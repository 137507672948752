import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Divider, Form, List, notification, Select, Typography, Upload, Row, Col, Tooltip } from 'antd';
import * as XLSX from 'xlsx';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const options = [
  <Option value='Regular work' key='Regular work'>
    Regular work
  </Option>,
  <Option value='Holiday leave' key='Holiday leave'>
    Holiday leave
  </Option>,
  <Option value='Public holiday' key='Public holiday'>
    Public holiday
  </Option>,
  <Option value='Sick leave up to 30 days 65%' key='Sick leave up to 30 days 65%'>
    Sick leave up to 30 days (65%)
  </Option>,
  <Option value='Sick leave up to 30 days 100%' key='Sick leave up to 30 days 100%'>
    Sick leave up to 30 days (100%)
  </Option>,
  <Option value='Sick leave more than 30 days 65%' key='Sick leave more than 30 days 65%'>
    Sick leave more than 30 days (65%)
  </Option>,
  <Option value='Sick leave more than 30 days 100%' key='Sick leave more than 30 days 100%'>
    Sick leave more than 30 days (100%)
  </Option>,
  <Option value='Pregnancy leave' key='Pregnancy leave'>
    Pregnancy leave
  </Option>,
  <Option value='Maternity leave' key='Maternity leave'>
    Maternity leave
  </Option>,
  <Option value='Other leave' key='Other leave'>
    Other leave
  </Option>,
  <Option value='Paid leave' key='Paid leave'>
    Paid leave
  </Option>,
  <Option value='Paid leave S' key='Paid leave S'>
    Slava - Saint day
  </Option>,
  <Option value='Non-paid leave' key='Non-paid leave'>
    Non-paid leave
  </Option>,
  <Option value='Weekend work' key='Weekend work'>
    Weekend work
  </Option>,
  <Option value='Night work' key='Night work'>
    Night work
  </Option>,
  <Option value='Night work on public holiday' key='Night work on public holiday'>
    Night work on public holiday
  </Option>,
  <Option value='Work on public holiday' key='Work on public holiday'>
    Work on public holiday
  </Option>,
  <Option value='Overtime work on public holiday' key='Overtime work on public holiday'>
    Overtime work on public holiday
  </Option>,
  <Option value='Unemployed' key='Unemployed'>
    Unemployed
  </Option>,
  <Option value='Bonus' key='Bonus'>
    Bonus
  </Option>,
];

const optionsIdentity = [
  <Option value='First name and surname' key='First name and surname'>
    First name and surname
  </Option>,
  <Option value='Employee ID no.' key='Employee ID no.'>
    Employee ID no.
  </Option>,
  <Option value='Email' key='Email'>
    Email
  </Option>,
  <Option value='Personal number' key='Personal number'>
    Personal number
  </Option>,
  <Option value='None' key='None'>
    None
  </Option>,
];

const optionsGeneral = [
  <Option value='From' key='From'>
    From
  </Option>,
  <Option value='To' key='To'>
    To
  </Option>,
  <Option value='None' key='None'>
    None
  </Option>,
];

const predefinedMatches = {
  'Serbian Vacation': 'Holiday leave',
  'Serbian Sick Leave': 'Sick leave up to 30 days 65%',
  'Slava Day': 'Paid leave S',
  'Serbian Maternity Leave': 'Maternity leave',
  'Time Off': 'Non-paid leave',
  'Serbian Paid Leave': 'Paid leave',
  'Serbian Pregnancy Leave': 'Pregnancy leave',
  Holiday: 'Holiday leave',
  Vacation: 'Holiday leave',
  'Sick Leave': 'Sick leave up to 30 days 65%',
  Sick_leave: 'Sick leave up to 30 days 65%',
  SICK_LEAVE: 'Sick leave up to 30 days 65%',
  BONUS: 'Bonus',
  VACATION: 'Holiday leave',
  'BONUS\r\nRSD NET': 'Bonus',
  'September 2024 OT': 'Overtime',
};

const employeeIdentityMatch = {
  'First Name': 'First name and surname',
  'Last Name': 'First name and surname',
  'Employee Number': 'Employee ID no.',
  Name: 'First name and surname',
  'EE Name': 'First name and surname',
  'PERSONAL ID NUMBER/JMBG ': 'Personal ID',
  // EMPLOYEE: 'First name',
  // __EMPTY: 'Surname',
};

const generalDataMatch = {
  'Total Days': 'Number of days from request',
  'Time off': 'Number of days from request',
  From: 'Date from',
  To: 'Date to',
  'Start Date': 'Date from',
  'End Date': 'Date to',
  Amount: 'Amount',
};

const SalaryUpload = () => {
  const currentYear = moment().year();
  const history = useHistory();
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const [isError, setIsError] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [platform, setPlatform] = useState(undefined);
  const [excelData, setExcelData] = useState([]);
  const [excelPairs, setExcelPairs] = useState([]);
  const [company, setCompanies] = useState([]);
  const [companyAndBranches, setCompanyAndBranches] = useState();
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [executed, setExecuted] = useState(true);
  const [identityData, setIdentityData] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [years, setYears] = useState('');
  const [excelColumnPattern, setExcelColumnPattern] = useState(null);
  const [isBonusType, setIsBonusType] = useState(false);
  const [isTypeWithNoCategories, setIsTypeWithNoCategories] = useState(false);
  const [wrongExcelFormatError, setWrongExcelFormatError] = useState(false);

  let initialValues = { year: currentYear.toString() };

  // this sets platform main pattern definition
  const getExcelColumnPattern = (columnsInExcel) => {
    if (platform === 'Bamboo') {
      return 'Category';
    } else if (platform === 'Edays') {
      return 'Absence Type';
    } else if (platform === 'Workmotion') {
      setIsBonusType(!columnsInExcel.includes('Type of Leave'));
      return columnsInExcel.includes('Type of Leave') ? 'Type of Leave' : 'Category';
    } else if (platform === 'FindStaff') {
      setIsBonusType(true);
      setIsTypeWithNoCategories(true);
      return 'BONUS\nRSD NET ';
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (excelData && excelData.length > 0) {
      const columnsInExcel = excelData.length > 0 ? Object.keys(excelData[0]) : [];
      const pattern = getExcelColumnPattern(columnsInExcel);
      setExcelColumnPattern(pattern);
    }
  }, [excelData, platform]);

  useEffect(() => {
    if (excelData && excelData.length > 0) {
      setFileList([]);
    }
  }, [excelData, isError]);

  // prepare data for year picker
  useEffect(() => {
    let years1 = [];
    for (let i = parseFloat(moment().format('YYYY')) - 10; i < parseFloat(moment().format('YYYY')) + 10; i++) {
      years1.push(
          <Option key={i} value={i}>
            {i}
          </Option>,
      );
    }
    setYears(years1);
  }, []);

  const getAllCompanies = async () => {
    try {
      const responseCompanies = await Axios.get(`${SERVER_URL}/companies`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setCompanies(responseCompanies.data);
      setExecuted(false);
    } catch (error) {
      console.error('error message', error.message);
    }
  };

  // set default company for hrOfficer role
  useEffect(() => {
    if (company && company.items && currentuser.data.role.includes('hrOfficer')) {
      const foundCompany = company.items.filter((item) => item._id === localStorage.getItem('selectedCompany'));
      setSelectedCompany(foundCompany[0]);
    }
  }, [company, currentuser]);

  useEffect(() => {
    if (executed) {
      getAllCompanies();
    }
  }, [executed, getAllCompanies]);

  const onFinish = async () => {
    const postData = {
      platform,
      excelPairs,
      generalPairs: generalData,
      identityPairs: identityData,
      excelData,
      company: selectedCompany ? selectedCompany._id : currentuser.data.company._id,
      month: selectedMonth,
      year: selectedYear.toString(),
      isBonusType,
    };

    try {
      await Axios.post(`${SERVER_URL}/salary-upload`, postData, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Salary excel upload successful. Check Timesheet section for updates.',
        placement: 'bottomRight',
      });
      form.resetFields();
      setFileList([]);
      history.push('/admin/salary');
    } catch (error) {
      console.error('error in saving salary upload categories', error);
      notification.error({
        message: 'Problem with Salary excel upload. Please contact admin or try again.',
        placement: 'bottomRight',
      });
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setExcelData([]);
    setExcelPairs([]);
    setIdentityData([]);
    setGeneralData([]);
  };

  const handleUpload = async (file) => {
    setFileList([]);
    setExcelData([]);
    setExcelPairs([]);
    setIdentityData([]);
    setGeneralData([]);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const excelItems = XLSX.utils.sheet_to_json(sheet);

      let detectedPlatform = 'Unknown';
      if (excelItems.length > 0) {
        const firstItem = excelItems[0];
        const firstColumnTitle = Object.keys(firstItem)[0];

        switch (firstColumnTitle) {
          case 'Employee Number':
            detectedPlatform = 'Bamboo';
            break;
          case 'EE ID':
            detectedPlatform = 'Workmotion';
            break;
          case 'First Name':
            detectedPlatform = 'Edays';
            break;
          case 'PERSONAL ID NUMBER/JMBG ':
            detectedPlatform = 'FindStaff';
            break;
          default:
            detectedPlatform = 'Unknown';
            break;
        }
      }

      setPlatform(detectedPlatform);
      setExcelData(excelItems);
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (platform === 'Unknown') {
      setWrongExcelFormatError(true);
      notification.warn({
        message: 'The system does not recognize this excel pattern. Please upload a different one.',
        placement: 'bottomRight',
      });
      setFileList([]);
    } setWrongExcelFormatError(false);
  }, [platform]);

  const findBestMatch = (category, listOfLeaves, usedValues, predefined) => {
    if (predefined[category] && !usedValues.has(predefined[category])) {
      return predefined[category];
    }

    const availableLeaves = listOfLeaves.filter((leaf) => !usedValues.has(leaf));
    if (availableLeaves.length === 0) {
      return null;
    }

    return availableLeaves;
  };

  const matchPairs = (uniqueCategories) => {
    const listOfLeaves = options.map((option) => option.props.value);
    let usedValues = new Set();

    return uniqueCategories.map((category) => {
      const matchingLeaf = findBestMatch(category, listOfLeaves, usedValues, predefinedMatches);
      if (matchingLeaf) {
        usedValues.add(matchingLeaf);
      }

      return {
        [category]: matchingLeaf,
      };
    });
  };

  const matchIdentityPairs = (uniqueCategories) => {
    const listOfIdentities = optionsIdentity.map((option) => option.props.value);
    let usedValues = new Set();

    return uniqueCategories.map((category) => {
      const matchingLeaf = findBestMatch(category, listOfIdentities, usedValues, employeeIdentityMatch);
      if (matchingLeaf) {
        usedValues.add(matchingLeaf);
      }

      return {
        [category]: matchingLeaf,
      };
    });
  };

  const matchGeneralPairs = (uniqueCategories) => {
    const listOfGenerals = optionsGeneral.map((option) => option.props.value);
    let usedValues = new Set();

    return uniqueCategories.map((category) => {
      const matchingLeaf = findBestMatch(category, listOfGenerals, usedValues, generalDataMatch);
      if (matchingLeaf) {
        usedValues.add(matchingLeaf);
      }

      return {
        [category]: matchingLeaf,
      };
    });
  };

  const updatePairs = (pairsSetter, newValue, oldValue) => {
    pairsSetter((currentPairs) =>
        currentPairs.map((pair) => {
          const pairKey = Object.keys(pair)[0];
          if (pair[pairKey] === oldValue) {
            return { [pairKey]: newValue };
          }
          return pair;
        }),
    );
  };

  useEffect(() => {
    let isMounted = true;
    if (excelData && excelColumnPattern) {
      const categories = excelData.map((item) => item[excelColumnPattern]);
      const identityCategories = excelData?.reduce((keys, obj) => {
        Object.keys(obj).forEach((key) => {
          if (!keys.includes(key) && employeeIdentityMatch[key]) {
            keys.push(key);
          }
        });
        return keys;
      }, []);
      const generalCategories = excelData?.reduce((keys, obj) => {
        Object.keys(obj).forEach((key) => {
          if (!keys.includes(key) && generalDataMatch[key]) {
            keys.push(key);
          }
        });
        return keys;
      }, []);
      const uniqueCategories = [...new Set(categories)];
      const uniqueIdentityCategories = [...new Set(identityCategories)];
      const uniqueGeneralCategories = [...new Set(generalCategories)];
      const matchedPairs = matchPairs(uniqueCategories);
      const matchedIdentityPairs = matchIdentityPairs(uniqueIdentityCategories);
      const matchedGeneralPairs = matchGeneralPairs(uniqueGeneralCategories);
      setExcelPairs(matchedPairs);
      setIdentityData(matchedIdentityPairs);
      setGeneralData(matchedGeneralPairs);
    }

    return () => {
      isMounted = false;
    };
  }, [excelData, excelColumnPattern]);

  const getUsedValues = (pairs) => {
    return pairs.map((pair) => Object.values(pair)[0]);
  };

  const selectCompany = (companyId) => {
    const foundCompany = company.items.filter((item) => item._id === companyId);
    setSelectedCompany(foundCompany[0]);
  };

  const renderItems = (item, data, options, setter) =>
      Object.entries(item).map(([key, initialValue]) => {
        const usedValues = getUsedValues(data);
        const availableOptions = options.filter(
            (option) => !usedValues.includes(option.props.value) || option.props.value === initialValue,
        );

        const SelectWithState = () => {
          const [selectedValue, setSelectedValue] = useState(initialValue);

          return (
              <React.Fragment>
                <Typography.Text mark>[{key}]</Typography.Text>
                <Form.Item labelAlign='left' style={{ marginBottom: 0 }}>
                  <Select
                      style={{ width: '320px', marginTop: '5px' }}
                      value={selectedValue}
                      onChange={(newValue) => {
                        setSelectedValue(newValue);
                        updatePairs(setter, newValue, initialValue);
                      }}
                      filterOption={(inputValue, option) =>
                          !usedValues.includes(option.props.value) || option.props.value === initialValue
                      }
                  >
                    {availableOptions.map((option) => option)}
                  </Select>
                </Form.Item>
              </React.Fragment>
          );
        };

        return (
            <List.Item key={`${key}-${initialValue}`}>
              <SelectWithState />
            </List.Item>
        );
      });

  return (
      <div className='dashboard'>
        <div className='card-wrapper' style={{ marginTop: '15px' }}>
          <Card title='Upload excel' bordered={false}>
            <h4 style={{ paddingBottom: '10px' }}>
              Here you can upload excel file and define categories you want to have in salary definition.
            </h4>
            <Form
                name='basic'
                initialValues={initialValues}
                onFinish={(values) => {
                  !wrongExcelFormatError ? onFinish(values) :  notification.warn({
                    message: 'The system does not recognize this excel pattern. Please upload a different one.',
                    placement: 'bottomRight',
                  });
                }}
                layout='horizontal'
                form={form}
            >
              {' '}
              {(currentuser.data.role.includes('SuperAdmin') ||
                  currentuser.data.role.includes('admin') ||
                  (companyAndBranches && companyAndBranches.length > 1)) && (
                  <Form.Item
                      label='Company'
                      name='company'
                      rules={[
                        {
                          required: true,
                          message: 'Please choose company!',
                        },
                      ]}
                  >
                    <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={(value) => selectCompany(value)}
                        style={{ marginLeft: '55px', width: '878px', marginTop: '5px' }}
                        dropdownStyle={{ width: '320px' }}
                    >
                      {(currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) &&
                          company &&
                          company.items &&
                          company.items.map((item, index) => (
                              <Option key={index} value={item._id}>
                                {item.name}
                              </Option>
                          ))}
                      {(!currentuser.data.role.includes('SuperAdmin') || !currentuser.data.role.includes('admin')) &&
                          companyAndBranches &&
                          companyAndBranches.length !== 0 &&
                          companyAndBranches.map((item, index) => (
                              <Option key={index} value={item._id}>
                                {item.name}
                              </Option>
                          ))}
                    </Select>
                  </Form.Item>
              )}
              <Row gutter={12}>
                <Col span={6}>
                  <Form.Item
                      label='Select year'
                      name='year'
                      rules={[
                        {
                          required: true,
                          message: 'Please select year!',
                        },
                      ]}
                  >
                    <Select
                        onChange={(value) => {
                          setSelectedYear(value.toString());
                        }}
                        className='select__year'
                        defaultValue={selectedYear}
                        value={selectedYear}
                        style={{ marginLeft: '48px' }}
                    >
                      {years}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                      label='Select month'
                      name='month'
                      rules={[
                        {
                          required: true,
                          message: 'Please select month!',
                        },
                      ]}
                      style={{ marginLeft: '48px' }}
                  >
                    <Select
                        className='select_month'
                        onChange={(value) => {
                          setSelectedMonth(value);
                        }}
                        value={selectedMonth}
                    >
                      <Option value='January'>January</Option>
                      <Option value='February'>February</Option>
                      <Option value='March'>March</Option>
                      <Option value='April'>April</Option>
                      <Option value='May'>May</Option>
                      <Option value='June'>June</Option>
                      <Option value='July'>July</Option>
                      <Option value='August'>August</Option>
                      <Option value='September'>September</Option>
                      <Option value='October'>October</Option>
                      <Option value='November'>November</Option>
                      <Option value='December'>December</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <div style={{ display: 'flex' }}>
                <Form.Item
                    name='fileOption'
                    label='Attach file'
                    className='attach'
                    rules={[
                      {
                        required: true,
                        message: 'Please upload the excel file!',
                      },
                    ]}
                >
                  <Upload
                      accept='.xlsx,.xls'
                      onRemove={handleRemove}
                      maxCount={1}
                      fileList={fileList.length === 0 && !isError ? undefined : isError ? [] : [...fileList]}
                      name='file'
                      multiple={false}
                      beforeUpload={(file) => {
                        setIsError(false);

                        if (file.size / 1000000 > 5) {
                          notification.error({
                            message: 'File is larger than 5 MB!',
                            placement: 'bottomRight',
                          });
                          return Promise.reject();
                        }

                        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                          notification.error({
                            message: 'Please upload an Excel file!',
                            placement: 'bottomRight',
                          });
                          return Promise.reject();
                        }

                        if (fileList.length > 0) {
                          notification.error({
                            message: 'You can only upload one file at a time!',
                            placement: 'bottomRight',
                          });
                          return Promise.reject();
                        }

                        handleUpload(file);
                        return false;
                      }}
                  >
                    <Button
                        id='uploadButton'
                        icon={<UploadOutlined />}
                        style={{ marginLeft: '54px', marginBottom: '10px' }}
                    >
                      Upload excel
                    </Button>
                    <Tooltip
                        title={
                          <>
                            Please make sure that platform is chosen before upload.
                            <br />
                            <strong>Note:</strong> You cannot upload the file larger than 5 MB and it HAS to be .xlxs file.
                          </>
                        }
                        color='#b1b1b1'
                        placement='bottom'
                        autoAdjustOverflow={true}
                    >
                      <QuestionCircleOutlined style={{ marginLeft: '5px', color: '#b1b1b1', marginBottom: '30px' }} />
                    </Tooltip>
                  </Upload>
                </Form.Item>
              </div>
              {excelPairs && excelPairs.length ? (
                  <>
                    {!isTypeWithNoCategories && (
                        <>
                          <Divider orientation='left'>List of excel columns</Divider>
                          <List
                              header={<div>Here you can define types of leave</div>}
                              footer={
                                <div>
                                  Defined pairs will be saved and you can use them as a pattern in following months. Anyway, you
                                  can make additional changes after you upload file for the next month.
                                </div>
                              }
                              bordered
                              dataSource={excelPairs}
                              renderItem={(item) => renderItems(item, excelPairs, options, setExcelPairs)}
                          />
                        </>
                    )}

                    <Divider orientation='left'>List of excel columns</Divider>
                    <List
                        header={<div>Here you can define identity for employee</div>}
                        bordered
                        dataSource={identityData}
                        renderItem={(item) => renderItems(item, identityData, optionsIdentity, setIdentityData)}
                    />

                    {!isTypeWithNoCategories && (
                        <>
                          <Divider orientation='left'>List of excel columns</Divider>
                          <List
                              header={<div>Here you can define general data</div>}
                              bordered
                              dataSource={generalData}
                              renderItem={(item) => renderItems(item, generalData, optionsGeneral, setGeneralData)}
                          />
                        </>
                    )}
                  </>
              ) : null}
              <div className='text-right' style={{ marginTop: '30px' }}>
                <Button type='primary' id='terminate' htmlType='submit'>
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
  );
};

export default SalaryUpload;
