import ExcelJS from 'exceljs';
import Axios from 'axios';
import { SERVER_URL } from '../../config/index.js';
import moment from 'moment';
import { notification } from 'antd';

export default async function createExcelAndZipFile(
    salaries,
    month,
    year,
    user,
    selectedCompany,
    language,
    monthDays,
    company,
    months,
    FileSaver,
    filesToDownload,
    setIsVisible,
    isSubmitForPayroll,
    dataForEmail,
) {
  try {
    setIsVisible(true);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Data');
    const columnsPartOne = [
      { header: language === 'chinese' ? '数字' : 'n.', key: 'num' },
      { header: language === 'chinese' ? '身份号' : 'ID number', key: 'personalNumber' },
      { header: language === 'chinese' ? '名' : 'Name', key: 'firstName' },
      { header: language === 'chinese' ? '姓' : 'Surname', key: 'lastName' },
    ];
    for (let i = 0; i < monthDays; i++) {
      columnsPartOne.push({ header: parseInt((i + 1).toString()), key: (i + 1).toString() });
    }
    columnsPartOne.push({
      header: language === 'chinese' ? '月工\r\n时总' : '  Total  h',
      key: 'totalWork',
      width: 3,
    });

    for (const type of company.workTypes) {
      switch (type) {
        case 'Regular work':
          columnsPartOne.push({
            header: language === 'chinese' ? '正常\r\n工作' : 'RW',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Holiday leave':
          columnsPartOne.push({
            header: language === 'chinese' ? '年假' : 'HL',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Sick leave up to 30 days 65%':
          columnsPartOne.push({
            header: language === 'chinese' ? '病假' : 'S30-(65)',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Sick leave up to 30 days 100%':
          columnsPartOne.push({
            header: language === 'chinese' ? '病假' : 'S30-(100)',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Sick leave more than 30 days 65%':
          columnsPartOne.push({
            header: language === 'chinese' ? '病假' : 'S30+(65)',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Sick leave more than 30 days 100%':
          columnsPartOne.push({
            header: language === 'chinese' ? '病假' : 'S30+(100)',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Pregnancy leave':
          columnsPartOne.push({
            header: language === 'chinese' ? '怀\r\n孕假' : 'PL',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Maternity leave':
          columnsPartOne.push({
            header: language === 'chinese' ? '怀\r\n孕假' : 'ML',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Public holiday':
          columnsPartOne.push({
            header: language === 'chinese' ? '国定\r\n假日' : 'PH',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Other leave':
          columnsPartOne.push({
            header: language === 'chinese' ? '其他\r\n休假' : 'OL',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Paid leave':
          columnsPartOne.push({
            header: language === 'chinese' ? '带薪\r\n休假' : 'PDL',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Non-paid leave':
          columnsPartOne.push({
            header: language === 'chinese' ? '无薪\r\n休假' : 'NPL',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Weekend work':
          columnsPartOne.push({
            header: language === 'chinese' ? '周末\r\n加班' : 'WW',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Night work':
          columnsPartOne.push({
            header: language === 'chinese' ? '夜工' : 'NW',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Night work on public holiday':
          columnsPartOne.push({
            header: language === 'chinese' ? '公眾假期上夜班' : 'NWPH',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Work on public holiday':
          columnsPartOne.push({
            header: language === 'chinese' ? '假日\r\n工作' : 'WPH',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Overtime work on public holiday':
          columnsPartOne.push({
            header: language === 'chinese' ? '公共假期加班' : 'OWPH',
            key: type.toString().replace(' ', ''),
          });
          break;
        case 'Unemployed':
          columnsPartOne.push({
            header: language === 'chinese' ? '待业' : 'U',
            key: type.toString().replace(' ', ''),
          });
          break;
        default:
          break;
      }
    }
    const columnsPartSecond = [
      { header: language === 'chinese' ? '加班1' : 'OT1', key: 'overTime1' },
      { header: language === 'chinese' ? '加班2' : 'OT2', key: 'overTime2' },
      { header: '', key: 'blank' },
      // { header: language === 'chinese' ? '奖金' : 'Bonus', key: 'bonus' },
      { header: language === 'chinese' ? '伙食\r\n津贴' : 'Meal allow', key: 'hotMeal' },
      { header: language === 'chinese' ? '假期\r\n津贴' : 'Holid allow', key: 'regres' },
      { header: language === 'chinese' ? '交通\r\n费' : 'Trav exp', key: 'travelExpenses' },
    ];
    if (company.otherIncome) {
      columnsPartSecond.push({ header: language === 'chinese' ? '其他\r\n收入' : 'Other incom', key: 'otherIncome' });
    }
    if (company.suspension) {
      columnsPartSecond.push({ header: language === 'chinese' ? '工资\r\n停发' : 'Susp', key: 'suspension' });
    }
    if (company.otherExpenses) {
      columnsPartSecond.push({ header: language === 'chinese' ? '其他\r\n成本' : 'Other Exp', key: 'otherExpenses' });
    }
    if (company.bonus) {
      columnsPartSecond.push({ header: language === 'chinese' ? '其他\r\n成本' : 'Bonus', key: 'bonus' });
    }
    if (company.workFromHomeType) {
      columnsPartSecond.push({ header: language === 'chinese' ? '工资\r\n停发' : 'WFH', key: 'workFromHome' });
    }

    columnsPartSecond.push({ header: language === 'chinese' ? '评论' : 'Remark', key: 'comment' });

    worksheet.columns = columnsPartOne.concat(columnsPartSecond);
    // formatiranje zaglavlja - sirina i alignment
    worksheet.columns.forEach((column) => {
      switch (column.header) {
        case 'ID number':
        case '身份号':
          column.width = 16;
          column.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 'Name':
        case '名':
        case '奖金':
          column.width = 10;
          column.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 'Surname':
        case '姓':
          column.width = 12;
          column.alignment = { vertical: 'middle', horizontal: 'center' };
          break;
        case 'Holid allow':
        case '假期\r\n津贴':
          column.width = 9;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        case 'S30-(65)':
        case 'S30-(100)':
        case '  Total  h':
          column.width = column.header.length - 3;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        case 'S30+(65)':
          column.width = column.header.length - 2.5;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        case 'S30+(100)':
          column.width = column.header.length - 3.2;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        case 'Meal allow':
        case 'Trav exp':
          column.width = column.header.length - 1.5;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        case '':
          column.width = 2;
          break;
        case 'Other incom':
        case 'Other Exp':
          column.width = column.header.length - 1;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
          // case 'Work from home':
        case 'Work from home':
          column.width = column.header.length - 1;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        case 'Remark':
        case '月工\r\n时总':
        case '怀\r\n孕假':
        case '国定\r\n假日':
        case '假日\r\n工作':
        case '周末\r\n加班':
        case '带薪\r\n休假':
        case '正常\r\n工作':
          column.width = column.header.length + 1;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
        default:
          column.width =
              column.header.toString().length <= 2 && !Number.isNaN(+column.header) ? 5.5 : column.header.length + 5;
          column.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          break;
      }
    });
    const row = worksheet.getRow(1);
    row.height = 30;
    // stavljanje bordera i boje zaglavlja
    row._cells.forEach((cell) => {
      if (cell._column._key !== 'blank') {
        worksheet.getCell(cell._address).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
      switch (cell._column._header) {
        case 'HL':
        case '年假':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4472c4' },
          };
          break;
        case 'S30-(65)':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cc99ff' },
          };
          break;
        case 'S30-(100)':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffff4b' },
          };
          break;
        case 'S30+(65)':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '66ffc2' },
          };
          break;
        case 'S30+(100)':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '85adad' },
          };
          break;
        case 'PL':
        case '怀\r\n孕假':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00ff00' },
          };
          break;
        case 'PH':
        case '国定\r\n假日':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffc000' },
          };
          break;
        case 'OL':
        case '其他\r\n休假':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'd9d9d9' },
          };
          break;
        case 'PDL':
        case '带薪\r\n休假':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'bdd7ee' },
          };
          break;
        case 'NPL':
        case '无薪\r\n休假':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'c6e0b4' },
          };
          break;
        case 'WW':
        case '周末\r\n加班':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ff8566' },
          };
          break;
        case 'NW':
        case '夜工':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '008080' },
          };
          break;
        case 'NWPH':
        case '公眾假期上夜班':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '014242' },
          };
          break;
        case 'WPH':
        case '假日\r\n工作':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ff661a' },
          };
          break;
        case 'OWPH':
        case '公共假期加班':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'c04a0e' },
          };
          break;
        case 'U':
        case '待业':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ccff99' },
          };
          break;
        default:
          break;
      }

      switch (cell._column._key) {
        case 'Sickleave up to 30 days 65%':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cc99ff' },
          };
          break;
        case 'Sickleave up to 30 days 100%':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffff4b' },
          };
          break;
        case 'Sickleave more than 30 days 65%':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '66ffc2' },
          };
          break;
        case 'Sickleave more than 30 days 100%':
          worksheet.getCell(cell._address).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '85adad' },
          };
          break;
          defaut: break;
      }
    });
    const columnRow = worksheet.getRow(1);

    for (const salary of salaries) {
      const response = await Axios.post(
          `${SERVER_URL}/excel-zip`,
          {
            salaries: JSON.stringify([salary]),
            month,
            year,
            user,
            company,
            monthDays,
            index: salaries.indexOf(salary),
            months,
            language,
          },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.data.token}` },
          },
      );
      let { rowFirst, overTimeRow, rowSecondWork, existWorkTypeSecond, expiredContract } = response.data;

      if (rowFirst) {
        worksheet.addRow(rowFirst);
      }
      const insertedRow = worksheet.lastRow;
      const rowsForStyle = [insertedRow];
      worksheet.addRow(overTimeRow);
      const insertedOverTimeRow = worksheet.lastRow;
      if (existWorkTypeSecond) {
        worksheet.addRow(rowSecondWork);
        const insertedRow2 = worksheet.lastRow;
        rowsForStyle.push(insertedRow2);
      }
      rowsForStyle.forEach((singleRow, i) => {
        for (const cell of singleRow._cells) {
          if (cell) {
            worksheet.getCell(cell._address).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
            if (parseFloat(cell.value) !== 0 && parseFloat(cell.value) % Math.floor(cell.value) !== 0) {
              cell.numFmt = '0.00';
            }
            for (const day of salary.hoursByDay) {
              if (moment(day.selectedDate).format('MMMM') === month) {
                if (i === 0 && parseInt(cell._column._key) === parseInt(new Date(day.selectedDate).getDate())) {
                  const column = worksheet.columns.filter(
                      (column) =>
                          (column._key === day.workType.replace(' ', '') && day.hours !== '00:00') ||
                          // || (day.workType.includes('Paid leave') && day.workType.replace(' ', '').includes(column._key))
                          (day.workType.includes('Pregnancy leave') &&
                              column._key === 'Pregnancyleave' &&
                              day.hours !== '00:00'),
                  );
                  if (column && column.length !== 0) {
                    switch (column[0]._header) {
                      case 'HL':
                      case '年假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '4472c4' },
                        };
                        break;
                      case 'S30-(65)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'cc99ff' },
                        };
                        break;
                      case 'S30-(100)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ffff4b' },
                        };
                        break;
                      case 'S30+(65)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '66ffc2' },
                        };
                        break;
                      case 'S30+(100)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '85adad' },
                        };
                        break;
                      case 'PL':
                      case '怀\r\n孕假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '00ff00' },
                        };
                        break;
                      case 'ML':
                      case '怀\r孕假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '419fa4' },
                        };
                        break;
                      case 'PH':
                      case '国定\r\n假日':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ffc000' },
                        };
                        break;
                      case 'OL':
                      case '其他\r\n休假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'd9d9d9' },
                        };
                        break;
                      case 'PDL':
                      case '带薪\r\n休假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'bdd7ee' },
                        };
                        break;
                      case 'NPL':
                      case '无薪\r\n休假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'c6e0b4' },
                        };
                        break;
                      case 'WW':
                      case '周末\r\n加班':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ff8566' },
                        };
                        break;
                      case 'NW':
                      case '夜工':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '008080' },
                        };
                        break;
                      case 'NWPH':
                      case '公眾假期上夜班':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '014242' },
                        };
                        break;
                      case 'WPH':
                      case '假日\r\n工作':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ff661a' },
                        };
                        break;
                      case 'OWPH':
                      case '公共假期加班':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'c04a0e' },
                        };
                        break;
                      case 'U':
                      case '待业':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ccff99' },
                        };
                        break;
                      default:
                        break;
                    }
                    switch (column[0]._key) {
                      case 'Sickleave up to 30 days 65%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'cc99ff' },
                        };
                        break;
                      case 'Sickleave up to 30 days 100%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ffff4b' },
                        };
                        break;
                      case 'Sickleave more than 30 days 65%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '66ffc2' },
                        };
                        break;
                      case 'Sickleave more than 30 days 100%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '85adad' },
                        };
                        break;
                        defaut: break;
                    }
                  } else {
                    if (day.workType === 'Paid leave S') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '3295EC' },
                      };
                    } else if (day.workType === 'Paid leave W') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '9BD0FF' },
                      };
                    } else if (day.workType === 'Paid leave D') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'BADFFF' },
                      };
                    } else if (day.workType === 'Paid leave O') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '295B87' },
                      };
                    }
                  }
                } else if (parseInt(cell._column._key) === parseInt(new Date(day.selectedDate).getDate())) {
                  const column = worksheet.columns.filter(
                      (column) =>
                          (day.workType2 && day.hours2 !== '00:00' && column._key === day.workType2.replace(' ', '')) ||
                          // || (day.workType.includes('Paid leave') && day.workType.replace(' ', '').includes(column._key))
                          (day.workType2 &&
                              day.hours2 !== '00:00' &&
                              day.workType2.includes('Pregnancy leave') &&
                              column._key === 'Pregnancyleave'),
                  );
                  if (column && column.length !== 0) {
                    switch (column[0]._header) {
                      case 'HL':
                      case '年假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '4472c4' },
                        };
                        break;
                      case 'S30-(65)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'cc99ff' },
                        };
                        break;
                      case 'S30-(100)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ffff4b' },
                        };
                        break;
                      case 'S30+(65)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '66ffc2' },
                        };
                        break;
                      case 'S30+(100)':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '85adad' },
                        };
                        break;
                      case 'PL':
                      case '怀\r\n孕假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '00ff00' },
                        };
                        break;
                      case 'ML':
                      case '怀\r孕假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '419fa4' },
                        };
                        break;
                      case 'PH':
                      case '国定\r\n假日':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ffc000' },
                        };
                        break;
                      case 'OL':
                      case '其他\r\n休假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'd9d9d9' },
                        };
                        break;
                      case 'PDL':
                      case '带薪\r\n休假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'bdd7ee' },
                        };
                        break;
                      case 'NPL':
                      case '无薪\r\n休假':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'c6e0b4' },
                        };
                        break;
                      case 'WW':
                      case '周末\r\n加班':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ff8566' },
                        };
                        break;
                      case 'NW':
                      case '夜工':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '008080' },
                        };
                        break;
                      case 'NWPH':
                      case '公眾假期上夜班':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '014242' },
                        };
                        break;
                      case 'WPH':
                      case '假日\r\n工作':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ff661a' },
                        };
                        break;
                      case 'OWPH':
                      case '公共假期加班':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'c04a0e' },
                        };
                        break;
                      case 'U':
                      case '待业':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ccff99' },
                        };
                        break;
                      default:
                        break;
                    }
                    switch (column[0]._key) {
                      case 'Sickleave up to 30 days 65%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'cc99ff' },
                        };
                        break;
                      case 'Sickleave up to 30 days 100%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'ffff4b' },
                        };
                        break;
                      case 'Sickleave more than 30 days 65%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '66ffc2' },
                        };
                        break;
                      case 'Sickleave more than 30 days 100%':
                        worksheet.getCell(cell._address).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: '85adad' },
                        };
                        break;
                        default: break;
                    }
                  } else {
                    if (day.workType2 === 'Paid leave S') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '3295EC' },
                      };
                    } else if (day.workType2 === 'Paid leave W') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '9BD0FF' },
                      };
                    } else if (day.workType2 === 'Paid leave D') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'BADFFF' },
                      };
                    } else if (day.workType2 === 'Paid leave O') {
                      worksheet.getCell(cell._address).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: '295B87' },
                      };
                    }
                  }
                }
              }
            }
          }

          if (!cell) {
            for (const columnCell of columnRow._cells) {
              let cellAddress = columnCell._address.slice(0, -1);
              cellAddress += singleRow._number;
              if (columnCell._column._key !== 'blank') {
                worksheet.getCell(cellAddress).border = {
                  top: { style: 'thin' },
                  left: { style: 'thin' },
                  bottom: { style: 'thin' },
                  right: { style: 'thin' },
                };
              }
              if (!Number.isNaN(+columnCell._column._key)) {
                const date = new Date(year, months[month], 0);
                date.setDate(parseInt(columnCell._column._key));
                if (!worksheet.getCell(cellAddress).value && (date.getDay() === 6 || date.getDay() === 0)) {
                  worksheet.getCell(cellAddress).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ffcc99' },
                  };
                }
              }
            }
          }
        }
      });

      for (const columnCell of columnRow._cells) {
        if (Number.isNaN(+columnCell._column._header)) {
          const cellAddress = columnCell._address.slice(0, -1);
          let range;
          if (existWorkTypeSecond) {
            range = `${cellAddress + (rowsForStyle[1]._number - 2)}:${cellAddress + rowsForStyle[1]._number}`;
          } else {
            range = `${cellAddress + (insertedOverTimeRow._number - 1)}:${cellAddress + insertedOverTimeRow._number}`;
          }
          worksheet.mergeCells(range);
        }
      }
      for (const cell of insertedOverTimeRow._cells) {
        if (cell) {
          worksheet.getCell(cell._address).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          if (parseFloat(cell.value) !== 0 && parseFloat(cell.value) % Math.floor(cell.value) !== 0) {
            cell.numFmt = '0.00';
          }
        } else {
          for (const columnCell of columnRow._cells) {
            let cellAddress = columnCell._address.slice(0, -1);
            cellAddress += insertedOverTimeRow._number;
            if (columnCell._column._key !== 'blank') {
              worksheet.getCell(cellAddress).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
            }
            if (!Number.isNaN(+columnCell._column._key)) {
              const date = new Date(year, months[month], 0);
              date.setDate(parseInt(columnCell._column._key));
              if (!worksheet.getCell(cellAddress).value && (date.getDay() === 6 || date.getDay() === 0)) {
                worksheet.getCell(cellAddress).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'ffcc99' },
                };
              }
            }
          }
        }
      }
    }

    let lastRowNum = worksheet.lastRow._number;

    worksheet.getCell('B' + (lastRowNum + 2).toString()).value = language === 'chinese' ? '传奇' : 'Legenda';
    lastRowNum += 6;
    company.workTypes.forEach((type) => {
      worksheet.getCell('C' + lastRowNum.toString()).alignment = {
        vertical: 'bottom',
        horizontal: 'left',
        wrapText: false,
      };
      switch (type) {
        case 'Regular work':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '正常工作' : 'RW';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '正常工作' : type;
          break;
        case 'Holiday leave':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '年假' : 'HL';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4472c4' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '年假' : type;
          break;
        case 'Sick leave up to 30 days 65%':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '病假' : 'S30-(65)';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'cc99ff' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '病假 至30天（支付65%）' : type;
          break;
        case 'Sick leave up to 30 days 100%':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '病假' : 'S30-(100)';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffff4b' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '病假 至30天（支付100%）' : type;
          break;
        case 'Sick leave more than 30 days 65%':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '病假' : 'S30+(65)';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '66ffc2' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '病假 超过30天 （支付65%）' : type;
          break;
        case 'Sick leave more than 30 days 100%':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '病假' : 'S30+(100)';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '85adad' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '病假 超过30天 （支付100%）' : type;
          break;
        case 'Pregnancy leave':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '怀孕假' : 'PL';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '00ff00' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '怀孕假' : 'Pregnancy leave';
          break;
        case 'Maternity leave':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '怀孕假' : 'ML';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '419fa4' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '怀孕假' : 'Maternity leave';
          break;
        case 'Public holiday':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '国定假日' : 'PH';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ffc000' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '国定假日' : type;
          break;
        case 'Other leave':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '其他休假' : 'OL';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'd9d9d9' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '其他休假' : type;
          break;
        case 'Paid leave':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '带薪休假' : 'PDL';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'bdd7ee' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '带薪休假' : type;
          ++lastRowNum;
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '9BD0FF' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '带薪婚假' : 'Paid leave - wedding';
          worksheet.getCell('C' + lastRowNum.toString()).alignment = {
            vertical: 'bottom',
            horizontal: 'left',
            wrapText: false,
          };
          ++lastRowNum;
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '3295EC' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '带薪休假家庭节日' : 'Paid leave - slava';
          worksheet.getCell('C' + lastRowNum.toString()).alignment = {
            vertical: 'bottom',
            horizontal: 'left',
            wrapText: false,
          };
          ++lastRowNum;
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'BADFFF' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '带薪丧假' : 'Paid leave - death';
          worksheet.getCell('C' + lastRowNum.toString()).alignment = {
            vertical: 'bottom',
            horizontal: 'left',
            wrapText: false,
          };
          ++lastRowNum;
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '295B87' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value =
              language === 'chinese' ? '带薪休假其他' : 'Paid leave - other';
          worksheet.getCell('C' + lastRowNum.toString()).alignment = {
            vertical: 'bottom',
            horizontal: 'left',
            wrapText: false,
          };
          break;
        case 'Non-paid leave':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '无薪休假' : 'NPL';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'c6e0b4' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '无薪休假' : type;
          break;
        case 'Weekend work':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '周末加班' : 'WW';
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ff8566' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '周末加班' : type;
          break;
        case 'Night work':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '夜工' : 'NW';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '008080' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '夜工' : type;
          break;
        case 'Night work on public holiday':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '公眾假期上夜班' : 'NWPH';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '014242' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '夜工' : type;
          break;
        case 'Work on public holiday':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '假日工作' : 'WPH';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ff661a' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '国定假日工作' : type;
          break;
        case 'Overtime work on public holiday':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '公共假期加班' : 'OWPH';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'c04a0e' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '国定假日工作' : type;
          break;
        case 'Unemployed':
          worksheet.getCell('B' + lastRowNum.toString()).value = language === 'chinese' ? '待业' : 'U';
          worksheet.getCell('B' + lastRowNum.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell('B' + lastRowNum.toString()).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ccff99' },
          };
          worksheet.getCell('C' + lastRowNum.toString()).value = language === 'chinese' ? '待业' : type;
          break;
        default:
          break;
      }
      lastRowNum++;
    });
    // add to Legend
    const lastRowNumLegend = worksheet.lastRow._number;
    worksheet.getCell('B' + (lastRowNumLegend + 1).toString()).value = language === 'chinese' ? '加班1' : 'OT1';
    worksheet.getCell('B' + (lastRowNumLegend + 1).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 1).toString()).value =
        language === 'chinese' ? '加班1（至32个小时）' : 'Overtime1 ';
    worksheet.getCell('C' + (lastRowNumLegend + 1).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 2).toString()).value = language === 'chinese' ? '加班2' : 'OT2';
    worksheet.getCell('B' + (lastRowNumLegend + 2).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 2).toString()).value =
        language === 'chinese' ? '加班 2 （超过32小时）' : 'Overtime2';
    worksheet.getCell('C' + (lastRowNumLegend + 2).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 3).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('B' + (lastRowNumLegend + 3).toString()).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffcc99' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 3).toString()).value = language === 'chinese' ? '周末' : 'Weekend';
    worksheet.getCell('C' + (lastRowNumLegend + 3).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 4).toString()).value = language === 'chinese' ? '奖金' : 'Bonus';
    worksheet.getCell('B' + (lastRowNumLegend + 4).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 4).toString()).value = language === 'chinese' ? '奖金' : 'Bonus';
    worksheet.getCell('C' + (lastRowNumLegend + 4).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 5).toString()).value =
        language === 'chinese' ? '伙食津贴' : 'Meal allow';
    worksheet.getCell('B' + (lastRowNumLegend + 5).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 5).toString()).value =
        language === 'chinese' ? '伙食津贴' : 'Meal allowance';
    worksheet.getCell('C' + (lastRowNumLegend + 5).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 6).toString()).value =
        language === 'chinese' ? '假期津贴' : 'Holid allow';
    worksheet.getCell('B' + (lastRowNumLegend + 6).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 6).toString()).value =
        language === 'chinese' ? '假期津贴' : 'Holiday allowance';
    worksheet.getCell('C' + (lastRowNumLegend + 6).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 7).toString()).value = language === 'chinese' ? '交通费' : 'Trav exp';
    worksheet.getCell('B' + (lastRowNumLegend + 7).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 7).toString()).value =
        language === 'chinese' ? '上下班交通费' : 'Travel expenses';
    worksheet.getCell('C' + (lastRowNumLegend + 7).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 8).toString()).value =
        language === 'chinese' ? '其他收入' : 'Other incom';
    worksheet.getCell('B' + (lastRowNumLegend + 8).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 8).toString()).value =
        language === 'chinese' ? '其他收入' : 'Other income';
    worksheet.getCell('C' + (lastRowNumLegend + 8).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 9).toString()).value = language === 'chinese' ? '工资停发' : 'Susp';
    worksheet.getCell('B' + (lastRowNumLegend + 9).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 9).toString()).value =
        language === 'chinese' ? '工资停发' : 'Suspension';
    worksheet.getCell('C' + (lastRowNumLegend + 9).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    worksheet.getCell('B' + (lastRowNumLegend + 10).toString()).value =
        language === 'chinese' ? '其他成本' : 'Other Exp';
    worksheet.getCell('B' + (lastRowNumLegend + 10).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 10).toString()).value =
        language === 'chinese' ? '其他成本' : 'Other expenses';
    worksheet.getCell('C' + (lastRowNumLegend + 10).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };
    worksheet.getCell('B' + (lastRowNumLegend + 10).toString()).value = language === 'chinese' ? '其他成本' : 'WFH ';
    worksheet.getCell('B' + (lastRowNumLegend + 10).toString()).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell('C' + (lastRowNumLegend + 10).toString()).value =
        language === 'chinese' ? '其他成本' : 'Work from home';
    worksheet.getCell('C' + (lastRowNumLegend + 10).toString()).alignment = {
      vertical: 'bottom',
      horizontal: 'left',
      wrapText: false,
    };

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([Buffer.from(buffer)], { type: 'applicationi/xlsx' });
    if (!isSubmitForPayroll) FileSaver.saveAs(blob, `Timesheet for ${company.name} - ${month} ${year} - ${new Date().getTime()}.xlsx`);

    let keys = [];
    for (let i = 0; i < filesToDownload.length; i++) {
      keys.push(filesToDownload[i].location);
    }

    const employeeData = [];
    let commentExist = false;

    salaries.forEach((salary) => {
      if (salary.comment) {
        commentExist = true;
      }
      if (!salary.employee.endDate) {
        employeeData.push({
          firstName: salary.employee.firstName,
          lastName: salary.employee.lastName,
          id: salary.employee._id,
          comment: salary.comment,
          workFromHome: salary.employee.workFromHome,
          otherExpenses: salary.otherExpenses ? salary.otherExpenses : salary.employee.otherExpensesValue,
          otherIncome: salary.otherIncome ? salary.otherIncome : salary.employee.otherIncomeValue,
          suspension: salary.suspension ? salary.suspension : salary.employee.suspensionValue,
        });
      }
    });

    let fileBlob;

    if ((keys.length !== 0 && keys[0] !== '') || commentExist) {
      const res = await Axios.post(
          `${SERVER_URL}/zip-file`,
          { keys: keys, user: user.data.id, employees: employeeData },
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${user.data.token}` },
          },
      );

      if (res.data.salariesWithError.length !== 0) {
        const employeeNames = [];
        res.data.salariesWithError.forEach((item) => {
          employeeNames.push(item.employee.firstName + ' ' + item.employee.lastName);
        });
        notification.info({
          message: 'Problem with downloading files for employees ' + employeeNames.join(' ,'),
          placement: 'bottomRight',
        });
      }

      const response = await Axios.get(`${SERVER_URL}/file/zip/${user.data.id}.zip`, {
        responseType: 'arraybuffer',
        withCredentials: false,
      });

      fileBlob = new Blob([response.data], { type: 'application/zip' });

      if (!isSubmitForPayroll) {
        FileSaver.saveAs(fileBlob, 'Attach - ' + company.name + `- ${month} ${year} -` + new Date().getTime() + '.zip')
        setIsVisible(false);
        setTimeout(async() => {
          window.location.reload();
        }, 2000);
      }
    }

    // this appends excel and zip files to emails when click on Submit for payroll
    if (isSubmitForPayroll) {
      try {
        dataForEmail.keys = filesToDownload.map((file) => file.location);
        const formData = new FormData();
        formData.append('file', blob, `Timesheet for ${company.name} - ${month} ${year} - ${new Date().getTime()}.xlsx`);
        if (fileBlob) {
          formData.append('zip', fileBlob, `Attach - ${company.name} - ${month} ${year} - ${new Date().getTime()}.zip`);
        }
        formData.append('data', JSON.stringify(dataForEmail));
        const response = await Axios.post(`${SERVER_URL}/email`, formData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${user.data.token}` },
        });
        if (response.data.message === 'email_sent') {
          notification.success({
            message: 'E-mail is sent.',
            placement: 'bottomRight',
          });

          setTimeout(async() => {
            setIsVisible(false);
            window.location.reload();
          }, 2000);
        }
      } catch (err) {
        console.log('error in sending email', err);
        notification.error({
          message: 'Problem with sending email. Please try again later',
          placement: 'bottomRight',
        });
        setIsVisible(false);
      }
    } else {
      setIsVisible(false);
    }
  } catch (error) {
    console.log('error in download', error);
    notification.error({
      message: 'Problem with download. Please try again later',
      placement: 'bottomRight',
    });
    setIsVisible(false);
  }
}
