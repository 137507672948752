import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import useAxios from '../../hooks/useAxios';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { CheckCircleFilled, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import SalaryForm from '../../components/forms/SalaryForm';
import { Divider, Modal, notification, Select, Spin } from 'antd';
import Table from '../../components/tables/SalaryTable';
import SalaryFormCompanyPicker from '../../components/SalaryFormCompanyPicker';
import SalaryFormDatePicker from '../../components/SalaryFormDatePicker';
import moment from 'moment';
import SalaryFormDownloadSection from '../../components/SalaryFormDownloadSection';
import CustomDocsDrawer from '../../components/CustomDocsDrawer';
import DocsSettings from '../../components/DocsSettings';
import createExcelAndZipFile from '../../components/csv/CreateExcelAndZipFile.js';
import * as FileSaver from 'file-saver';
import { useTimesheetContext, useTimesheetContextDispatch } from '../../context/TimesheetContext.jsx';
import {formatDate, formatEInvoiceDate} from '../../helpers/dates';

const { Option } = Select;

const Salary = () => {
  const currentuser = useContext(UserContext);
  const [employees, setEmployees] = useState(undefined);
  const [salaries, setSalaries] = useState(undefined);
  const [dataTable, setDataTable] = useState(undefined);
  const [dataSalaries, setDataSalaries] = useState(undefined);
  const [randomKey, setRandomKey] = useState(undefined);
  const [salaryTableData, setSalaryTableData] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [display, setDisplay] = useState('none');
  const [companyList, setCompanyList] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [years, setYears] = useState(undefined);
  const [allCompanies, fetchAllCompanies] = useAxios('', [], currentuser.data.token, 'get');
  const [chosenCompany, setChosenCompany] = useState([]);
  const [findedSalary, setFindedSalary] = useState([]);
  const [singleMonth, setSingleMonth] = useState();
  const [unsetEmployee, setUnsetEmployee] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [employeesInSalary, setEmployeesInSalary] = useState([]);
  const [isNew, setIsNew] = useState(true);
  const [onXIcon, setOnXIcon] = useState(false);
  const [resetFormFieldValues, setResetFormFieldValues] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [customDay, setCustomDay] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [allDone, setAllDone] = useState(false);
  const [randomTableKey, setRandomTableKey] = useState(0);
  const [selectedYear, setSelectedYear] = useState(undefined);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [employeeReq, setEmployeeReq] = useState(undefined);
  const [foundCompany, setFoundCompany] = useState(false);
  const [companies, setCompanies] = useState(undefined);
  const [employee, setEmployee] = useState(undefined);
  const [selectedLang, setSelectedLang] = useState('english');
  const [submitted, setSubmitted] = useState(false);
  const [selectedEmployeeIndicator, setSelectedEmployeeIndicator] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [employeeFormData, setEmployeeFormData] = useState(
    localStorage.getItem('employeeData') ? JSON.parse(localStorage.getItem('employeeData')) : [],
  );
  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem('companySalary') ? localStorage.getItem('companySalary') : undefined,
  );
  const [customDocsDrawer, setCustomDocsDrawer] = useState(false);
  const [submitTimesheetDisabled, setSubmitTimesheetDisabled] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const salaryFormFixRef = useRef(null);

  const months = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  // set selected company in case user/client is for branch company
  useEffect(() => {
    const currentDate = new Date();
    const selectedMonthFromLS = localStorage.getItem('selectedMonth');
    const selectedYearFromLS = localStorage.getItem('selectedYear');
    if (
      (currentuser.data.role.includes('user') || currentuser.data.role.includes('userOfficer') || currentuser.data.role.includes('hrOfficer')) &&
      !currentuser.data.role.includes('admin') &&
      !currentuser.data.role.includes('SuperAdmin') &&
      currentuser.data.company
    ) {
      setSelectedCompany(currentuser.data.company._id);
      localStorage.setItem('companySalary', currentuser.data.company._id);
      if (!selectedMonthFromLS) {
        localStorage.setItem('selectedMonth', currentDate.toLocaleDateString('default', { month: 'long' }));
        setSelectedMonth(
          selectedMonthFromLS ? selectedMonthFromLS : currentDate.toLocaleDateString('default', { month: 'long' }),
        );
      }

      if (!selectedYearFromLS) {
        setSelectedYear(
          selectedYearFromLS ? selectedYearFromLS : currentDate.toLocaleDateString('default', { year: 'numeric' }),
        );
        localStorage.setItem('selectedYear', currentDate.toLocaleDateString('default', { year: 'numeric' }));
      }
    }
    // if (currentuser.data.role.includes('hrOfficer')) {
    //   setSelectedCompany(currentuser.data.);
    //   localStorage.setItem('companySalary', currentuser.data.company._id);
    //   if (!selectedMonthFromLS) {
    //     localStorage.setItem('selectedMonth', currentDate.toLocaleDateString('default', { month: 'long' }));
    //     setSelectedMonth(
    //         selectedMonthFromLS ? selectedMonthFromLS : currentDate.toLocaleDateString('default', { month: 'long' }),
    //     );
    //   }
    //
    //   if (!selectedYearFromLS) {
    //     setSelectedYear(
    //         selectedYearFromLS ? selectedYearFromLS : currentDate.toLocaleDateString('default', { year: 'numeric' }),
    //     );
    //     localStorage.setItem('selectedYear', currentDate.toLocaleDateString('default', { year: 'numeric' }));
    //   }
    // }
  }, []);

  const onClose = () => {
    setCustomDocsDrawer(false);
  };

  // month and year set
  useEffect(() => {
    const currentDate = new Date();
    const selectedMonthFromLS = localStorage.getItem('selectedMonth');
    const selectedYearFromLS = localStorage.getItem('selectedYear');

    setSelectedMonth(
      selectedMonthFromLS ? selectedMonthFromLS : currentDate.toLocaleDateString('default', { month: 'long' }),
    );
    setSelectedYear(
      selectedYearFromLS ? selectedYearFromLS : currentDate.toLocaleDateString('default', { year: 'numeric' }),
    );
    if (!selectedMonthFromLS) {
      localStorage.setItem('selectedMonth', currentDate.toLocaleDateString('default', { month: 'long' }));
    }

    if (!selectedYearFromLS) {
      localStorage.setItem('selectedYear', currentDate.toLocaleDateString('default', { year: 'numeric' }));
    }
  }, [salaries, employees]);

  // fetch companies
  useEffect(() => {
    if (currentuser.data.role.includes('admin') || currentuser.data.role.includes('SuperAdmin')) {
      let companyFields = {
        _id: 1,
        name: 1,
        client: 1,
        workTypes: 1,
        overTime2: 1,
        otherIncome: 1,
        suspension: 1,
        bonus: 1,
        otherExpenses: 1,
        mainCompany: 1,
        emailReceiver: 1,
        isHr: 1,
        companyDocsSettings: 1,
        holidayDaysTracker: 1,
        documentReturnType: 1,
        documentSeparateRequestResponse: 1,
        weekendLeaves: 1,
      };
      fetchAllCompanies(`${SERVER_URL}/companies?select=${JSON.stringify(companyFields)}`);
    }

    localStorage.removeItem('calendarData');
    localStorage.removeItem('calendarDataBackup');
  }, []);

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchSalariesData();
      fetchData();
      setCalendarVisible(false);
      setShowCalendar(false);
      localStorage.removeItem('calendarData');
    }
  }, [selectedCompany, selectedMonth, selectedYear]);

  // fetch employees and companies
  const fetchData = async () => {
    if (currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) {
      if (selectedCompany) {
        const filter = { $or: [{ _id: selectedCompany }, { mainCompany: selectedCompany }] };
        let companyFetchData;
        try {
          companyFetchData = await Axios.get(
            `${SERVER_URL}/companies?filter=${JSON.stringify(
              filter,
            )}&selectedMonth=${selectedMonth}&selectedYear=${selectedYear}`,
            {
              withCredentials: false,
              headers: { Authorization: `Bearer ${currentuser.data.token}` },
            },
          );
          setCompanies(companyFetchData);
        } catch (error) {
          console.log('error fetching company', error);
        }

        const companiesId =
        companyFetchData &&
        companyFetchData.data &&
        companyFetchData.data.items &&
        companyFetchData.data.items.map((item) => item._id);
        if (companiesId && companiesId.length !== 0) {
          const filterData = { company: { $in: companiesId ? companiesId : [] } };
          try {
            const employeesFetchData = await Axios.get(
              `${SERVER_URL}/test?filter=${JSON.stringify(
                filterData,
              )}&selectedMonth=${selectedMonth}&selectedYear=${selectedYear}`,
              {
                withCredentials: false,
                headers: { Authorization: `Bearer ${currentuser.data.token}` },
              },
            );
            setEmployees(employeesFetchData);
          } catch (error) {
            console.log('error in fetching employees', error);
          }
        }
      }
    } else {
      const filter = currentuser.data.role.includes('userOfficer')
        ? { userOfficer: currentuser.data.id }
          : currentuser.data.role.includes('hrOfficer')
      ? { _id: currentuser.data.company._id }
        : { client: currentuser.data.id };
      let companyFetchData2;
      try {
        companyFetchData2 = await Axios.get(
          `${SERVER_URL}/companies?filter=${JSON.stringify(
            filter,
          )}&selectedMonth=${selectedMonth}&selectedYear=${selectedYear}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        setCompanies(companyFetchData2);
      } catch (error) {
        console.log('error fetching company', error);
      }
    }
  };

  useEffect(() => {
    async function fetchBranchAndEmployeesData() {
      let select = {
        _id: 1,
        firstName: 1,
        lastName: 1,
        JMBG: 1,
        company: 1,
        updatedAt: 1,
        startDate: 1,
        endDate: 1,
        workFromHome: 1,
        employeeSector: 1,
        careerHistory: 1
      };
      if (companies) {
        const companiesId =
          companies && companies.data && companies.data.items && companies.data.items.map((item) => item._id);
        if (companiesId && companiesId.length !== 0) {
          const filterBranch = { mainCompany: companiesId[0] };
          try {
            await fetchBranches(filterBranch).then(async (r) => {
              r && r.data && r.data.items && r.data.items.forEach((item) => companiesId.push(item._id));
              let filterData = { company: { $in: companiesId ? companiesId : [] } };
              if (currentuser.data.role.includes('hrOfficer')) {
                filterData = { company: { $in: companiesId ? companiesId : [] }, employeeSector: currentuser.data.sectorId };
              }
              try {
                const employeesFetchData = await Axios.get(
                  `${SERVER_URL}/test?filter=${JSON.stringify(filterData)}&select=${JSON.stringify(
                    select,
                  )}&selectedMonth=${selectedMonth}&selectedYear=${selectedYear}`,
                  {
                    withCredentials: false,
                    headers: { Authorization: `Bearer ${currentuser.data.token}` },
                  },
                );
                setEmployees(employeesFetchData);
              } catch (error) {
                console.log('error in fetching employees', error);
              }
            });
          } catch (error) {
            console.log('error', error);
          }
        }
      }
    }
    fetchBranchAndEmployeesData();
  }, [companies]);

  // fetch branches
  const fetchBranches = async (filterBranch) => {
    try {
      return await Axios.get(`${SERVER_URL}/companies?filter=${JSON.stringify(filterBranch)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } catch (error) {
      console.log('error fetching salaries', error);
    }
  };

  // fetch salaries
  const fetchSalariesData = async () => {
    if (selectedCompany && selectedMonth && selectedYear) {
      try {
        const salariesFetch = await Axios.get(
          `${SERVER_URL}/salary-company-by-date?company=${selectedCompany}&month=${selectedMonth}&year=${selectedYear}`,
          {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          },
        );
        setSalaries(salariesFetch);
      } catch (error) {
        console.log('error fetching salaries', error);
      }
    }
  };

  const { terminateEmploymentFormData, extendEmploymentFormData } = useTimesheetContext();
  const {
    handleSubmitTerminateEmployment,
    handleSubmitExtendEmployment,
    resetTerminateEmploymentData,
    resetExtendEmploymentData,
  } = useTimesheetContextDispatch();

  useEffect(() => {
    resetTerminateEmploymentData();
    resetExtendEmploymentData();
  }, [selectedEmployee]);

  const onSubmit = async (formData, isNew, requestModalDataInitial) => {
    // this confirms only dates from requestModalData which are also confirmed through formData
    // dates booked manually through timesheet have priority upon dates from requestModalData
    let filteredRequestModalDataInitial;
    const requestModalDataDateRange = [];

    if (requestModalDataInitial && requestModalDataInitial.length) {
      requestModalDataInitial.map((item) => {
        if (item.hasOwnProperty('numOfDays')) {
          requestModalDataDateRange.push(item);
        }
      });
      filteredRequestModalDataInitial = requestModalDataInitial?.filter((requestData) => {
        const correspondingHour = formData.hoursByDay.find((hourData) => {
          return moment(hourData.selectedDate).isSame(moment(requestData.startDate), 'day');
        });

        return correspondingHour && correspondingHour.workType === requestData.typeOfLeave;
      });
    }

    const postData = {
      ...formData,
      requestModalData:
        filteredRequestModalDataInitial && filteredRequestModalDataInitial.length
          ? filteredRequestModalDataInitial?.map((item) => item)
          : [],
      requestModalDataDateRange,
    };

    const isValidDate = (date) => {
      return date instanceof Date && !isNaN(date);
    };

    if (postData && postData.requestModalData && postData.requestModalData.length) {
      const requestModalData = postData.requestModalData;
      const hoursByDay = postData.hoursByDay;
      postData.requestModalData = requestModalData.filter((requestData) => {
        const correspondingHour = hoursByDay.find((hourData) => {
          const selectedDate = new Date(hourData.selectedDate);
          const startDate = new Date(requestData.startDate);

          if (isValidDate(selectedDate) && isValidDate(startDate)) {
            return selectedDate.toISOString().split('T')[0] === startDate.toISOString().split('T')[0];
          } else {
            console.error('Invalid date(s) provided');
            return false;
          }
        });

        return !!correspondingHour;
      });
    }

    try {
      if (!isNew) {
        delete postData.id;
        postData.month = findedSalary[0].month;
        postData.year = findedSalary[0].year;
      }
      await Axios.put(
        `${SERVER_URL}/request-check-timesheet/${postData.employee}`,
        { postData },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      if (isNew) {
        await Axios.post(`${SERVER_URL}/salary`, postData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      } else {
        const id = findedSalary[0]._id;
        await Axios.put(`${SERVER_URL}/salary/${id}`, postData, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }
      if (terminateEmploymentFormData && !!Object.keys(terminateEmploymentFormData).length) {
        await handleSubmitTerminateEmployment(terminateEmploymentFormData, currentuser);
      }

      // Add call to handleSubmitExtendEmployment
      if (extendEmploymentFormData && !!Object.keys(extendEmploymentFormData).length) {
        await handleSubmitExtendEmployment(extendEmploymentFormData, currentuser);
      }

      notification.success({
        message: 'Record is entered.',
        placement: 'bottomRight',
      });
      setCalendarVisible(false);
      setShowCalendar(false);
      localStorage.removeItem('calendarData');
      localStorage.removeItem('calendarDataBackup');
      setIsNew(true);
      setSubmitted(true);
      setSelectedEmployee(undefined);
      setSubmitClicked(false);
      setSubmitTimesheetDisabled(false);
      await fetchSalariesData();
      await fetchData();
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
      setSubmitClicked(false);
      setSubmitTimesheetDisabled(false);
    }
  };

  const fetchSingleCompany = async (companyId) => {
    try {
      const res = await Axios.get(`${SERVER_URL}/companies/${companyId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setSelectedCompany(res.data._id);
      const array = [];
      array.push(res.data);
      setChosenCompany(array);
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  // this triggers when Docs settings is submitted
  // re-fetch company and reset states
  const resetState = async (companyId) => {
    setCalendarVisible(false);
    setShowCalendar(false);
    localStorage.removeItem('calendarData');
    setIsNew(true);
    setSubmitted(true);
    setSelectedEmployee(undefined);
    await fetchSingleCompany(companyId);
    await fetchSalariesData();
    await fetchData();
  };

  const deleteSalary = async (salaryId) => {
    try {
      await Axios.delete(`${SERVER_URL}/salary/${salaryId}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'Record is deleted.',
        placement: 'bottomRight',
      });
      setCalendarVisible(false);
      setSelectedEmployee(undefined);
      await fetchSalariesData();
      await fetchData();
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const updateDateEmployee = async () => {
    const body = {
      updatedAt: new Date(),
      type: 'updateDate',
    };
    await Axios.put(
      `${SERVER_URL}/data/${selectedEmployee}`,
      body,
      {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      },
    );
  };

  // TODO recheck the need for this
  // triggered from salaryForm file when calendar fields updates happens
  // just to collect information about updating
  // TODO recheck the need for this
  const updateDateEmployeeSubmit = async () => {
    // const body = {
    //   updatedAt: new Date(),
    //   type: 'updateDate',
    // };
    // await Axios.put(`${SERVER_URL}/data/${selectedEmployee}`, body, {
    //   withCredentials: false,
    //   headers: { Authorization: `Bearer ${currentuser.data.token}` },
    // });
  };

  // triggers everytime when selected company, year or month change
  useEffect(() => {
    if (employees && salaries) {
      tableAndSalariesData(employees, salaries);
    }
  }, [employees, salaries]);

  // prepare data objects for table
  const tableAndSalariesData = (emp, sal) => {
    let tableData = {
      items: [],
    };
    let items = [];
    let salariesData;
    if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId) {
      emp?.data?.items?.map((item) => {
        if (item.employeeSector === currentuser.data.sectorId) {
          items.push(item);
        }
      });
      tableData.items = items;
    } else {
      tableData = employees.data;
    }

    // filter salaries of only employees that are part of specific sector
    if (currentuser.data.role.includes('hrOfficer') && currentuser.data.sectorId) {
      salariesData = sal?.data?.items?.filter((item) => {
        if (item.employee.employeeSector) {
          if (item.employee.employeeSector === currentuser.data.sectorId) {
            return item;
          }
        }
      });
      tableData.items = items;
    } else {
      salariesData = salaries.data.items;
    }
    setDataTable(tableData);
    setDataSalaries(salariesData);
    setRandomKey(Math.random());
  };

  // prepare data for company select component
  useEffect(() => {
    let companyList1 = [];
    let chosenCompany1 = [];
    if (allCompanies && allCompanies.data && allCompanies.data.items) {
      for (let index = 0; index < allCompanies.data.items.length; index++) {
        companyList1.push(
          <Option key={index} value={allCompanies.data.items[index]._id}>
            {allCompanies.data.items[index].name}{' '}
            {allCompanies.data.items[index].mainCompany ? ' - ' + allCompanies.data.items[index].mainCompany.name : ''}
          </Option>,
        );
        setCompanyList(companyList1);
        if (selectedCompany && allCompanies.data.items[index]._id === selectedCompany) {
          chosenCompany1.push(allCompanies.data.items[index]);
          setChosenCompany(chosenCompany1);
        }
      }
    }
  }, [allCompanies, selectedCompany]);

  // prepare salaries data
  useEffect(() => {
    let findedSalary1 = [];
    if (dataSalaries && dataSalaries?.length) {
      for (const salary of dataSalaries) {
        if (
          salary?.employee?._id === selectedEmployee &&
          (salary.month === selectedMonth || salary.month === singleMonth) &&
          salary.year === moment(selectedYear).format('YYYY')
        ) {
          findedSalary1.push(salary);
        }
      }
    }

    setFindedSalary(findedSalary1);
    setRandomKey(Math.random());
  }, [selectedEmployee]);

  // prepare data for year picker
  useEffect(() => {
    let years1 = [];
    for (let i = parseFloat(moment().format('YYYY')) - 10; i < parseFloat(moment().format('YYYY')) + 10; i++) {
      years1.push(
        <Option key={i} value={i}>
          {i}
        </Option>,
      );
    }
    setYears(years1);
  }, []);

  // handle click X in table
  const clickXSubmit = async (id, year, month) => {
    const data = {
      id,
      year,
      month,
    };
    try {
      await Axios.post(`${SERVER_URL}/salary-on-x-click`, data, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setCalendarVisible(false);
      setShowCalendar(false);
      localStorage.removeItem('calendarData');
      setIsNew(true);
      notification.success({
        message: 'Record is entered.',
        placement: 'bottomRight',
      });
      setSubmitted(true);
      setSelectedEmployee(undefined);
      await fetchSalariesData();
      await fetchData();
    } catch (error) {
      const msg = error.response ? error.response.data.message : error.message ? error.message : error;
      console.log(msg);
      notification.error({
        message: msg,
        placement: 'bottomRight',
      });
    }

    setDisplay('none');
  };

  // triggers when submit for payroll
  const sendMail = async () => {
    let submittedIndicator = false;

    const salaries1 = salaries
      ? salaries.data.items.filter(
          (item) =>
            item.employee &&
            item.month === selectedMonth &&
            selectedYear &&
            selectedYear === item.year &&
            (item?.employee?.company?._id === selectedCompany ||
              item?.employee?.company?.mainCompany?._id === selectedCompany ||
              item?.employee?.company?._id === foundCompany ||
              item?.employee?.company?.mainCompany?._id === foundCompany),
        )
      : [];

    let foundCompany =
        salaries1 &&
        salaries1.length !== 0 &&
        salaries1.filter(
            (salary) =>
                (!currentuser.data.role.includes('admin') &&
                    !currentuser.data.role.includes('SuperAdmin') &&
                    (salary.employee.company?.client?._id === currentuser.data.id ||
                        salary.employee.company?.userOfficer?.includes(currentuser.data.id))) ||
                ((currentuser.data.role.includes('SuperAdmin') || currentuser.data.role.includes('admin')) &&
                    salary.employee.company?._id === selectedCompany),
        );

    if (foundCompany.length === 0) {
      foundCompany = salaries && salaries.length !== 0 && salaries[0].employee.company.mainCompany;
    } else if (foundCompany) {
      foundCompany = foundCompany[0].employee.company;
    }

    const company = foundCompany;
    const months = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12,
    };
    const monthDays = new Date(+selectedYear, months[selectedMonth], 0).getDate();
    let filesToDownload = [];
    for (const data of salaries1) {
      if (data.attachments) {
        for (const attachment of data.attachments) {
          filesToDownload.push({ ...attachment, employee: data.employee.firstName + ' ' + data.employee.lastName });
        }
      }
    }

    salaries1.map((item) => {
      if (item.submittedIndicator) submittedIndicator = true;
    });

    if (salaries.length !== 0) {
      let dataForEmail = {};
      if (selectedYear && allDone && companies && companies.data && companies.data.items) {
        dataForEmail = {
          client: currentuser.data,
          companies: chosenCompany,
          month: selectedMonth,
          year: moment(selectedYear).format('YYYY'),
        };

        if (dataForEmail && dataForEmail.companies) {
          await createExcelAndZipFile(
            salaries1,
            selectedMonth,
            selectedYear,
            currentuser,
            selectedCompany,
            'english',
            monthDays,
            company,
            months,
            FileSaver,
            filesToDownload,
            setIsVisible,
            true,
            dataForEmail,
          );
        }
      }
    } else {
      notification.info({
        message: 'No data to export.',
        placement: 'bottomRight',
      });
    }
    document.getElementById('payrollButton').style.pointerEvents = 'none';
  };

  const handleFetchAndSetEmployee = useCallback(async (selectedEmployeeParam) => {
    fetchSelectedEmployee(selectedEmployeeParam).then((r) => setEmployee(r));
  }, []);

  // fetch data for selected employee
  useEffect(() => {
    if (selectedEmployee) {
      handleFetchAndSetEmployee(selectedEmployee);
      // fetchSelectedEmployee().then((r) => setEmployee(r));
    }
  }, [selectedEmployee]);

  const fetchSelectedEmployee = async (selectedEmployeeParam) => {
    try {
      return await Axios.get(`${SERVER_URL}/data-by-id/${selectedEmployeeParam}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
    } catch (error) {
      console.log('error fetching salaries', error);
    }
  };

  // prepare data from salaries and employees collections for calendar
  useEffect(() => {
    let filteredEmployees1 = [];
    let employeesArray = [];
    let employeesInSalary1 = [];

    if (salaries && employees && employees.data.items) {
      if (
        currentuser.data.role.includes('SuperAdmin') ||
        currentuser.data.role.includes('admin') ||
        currentuser.data.role.includes('user') ||
        currentuser.data.role.includes('userOfficer') ||
        currentuser.data.role.includes('hrOfficer')
      ) {
        for (const employeeItem of employees.data.items) {
          if (
            (employeeItem.company?._id === selectedCompany || employeeItem.company?.mainCompany === selectedCompany) &&
            (!employeeItem.endDate ||
              (employeeItem.endDate &&
                (parseInt(moment(employeeItem.endDate).format('MM')) >= parseInt(months[selectedMonth]) ||
                  parseInt(moment(employeeItem.endDate).format('YYYY')) >=
                    parseInt(moment(selectedDate).format('YYYY'))))) &&
            employeeItem.startDate &&
            (parseInt(moment(employeeItem.startDate).format('MM')) <= parseInt(months[selectedMonth]) ||
              parseInt(moment(employeeItem.startDate).format('YYYY')) <= parseInt(moment(selectedDate).format('YYYY')))
          ) {
            filteredEmployees1.push(employeeItem);
          }
        }

        for (const filteredEmployeeItem of filteredEmployees1) {
          for (const salary of salaries.data.items) {
            if (
              salary.employee &&
              salary.employee._id === filteredEmployeeItem._id &&
              (salary.month === selectedMonth || salary.month === singleMonth) &&
              salary.year === moment(selectedDate).format('YYYY')
            ) {
              employeesInSalary1.push(filteredEmployeeItem._id);
            }
          }
        }

        if ([...new Set(employeesInSalary1)].length === filteredEmployees1.length && selectedCompany) {
          setAllDone(true);
        }
      } else {
        for (const employeeItem of employees.data.items) {
          for (const salary of salaries.data.items) {
            if (
              salary.employee &&
              salary.employee._id === employeeItem._id &&
              (salary.month === selectedMonth || salary.month === singleMonth) &&
              salary.year === moment(selectedDate).format('YYYY') &&
              (!employeeItem.endDate ||
                (employeeItem.endDate &&
                  (parseInt(moment(employeeItem.endDate).format('MM')) >= parseInt(months[selectedMonth]) ||
                    parseInt(moment(employeeItem.endDate).format('YYYY')) >=
                      parseInt(moment(selectedDate).format('YYYY'))))) &&
              employeeItem.startDate &&
              (parseInt(moment(employeeItem.startDate).format('MM')) <= parseInt(months[selectedMonth]) ||
                parseInt(moment(employeeItem.startDate).format('YYYY')) <=
                  parseInt(moment(selectedDate).format('YYYY')))
            ) {
              [...new Set(employeesInSalary1)].push(employeeItem._id);
            }
          }
        }
        if (companies && companies.data && companies.data.items) {
          for (const employeeItem of employees.data.items) {
            for (const company of companies.data.items) {
              if (
                company._id === employeeItem.company._id &&
                (!employeeItem.endDate ||
                  (employeeItem.endDate &&
                    (parseInt(moment(employeeItem.endDate).format('MM')) >= parseInt(months[selectedMonth]) ||
                      parseInt(moment(employeeItem.endDate).format('YYYY')) >=
                        parseInt(moment(selectedDate).format('YYYY'))))) &&
                employeeItem.startDate &&
                (parseInt(moment(employeeItem.startDate).format('MM')) <= parseInt(months[selectedMonth]) ||
                  parseInt(moment(employeeItem.startDate).format('YYYY')) <=
                    parseInt(moment(selectedDate).format('YYYY')))
              ) {
                employeesArray.push(employeeItem._id);
              }
            }
            if (employeesArray.includes(employeeItem._id)) {
              filteredEmployees1.push(employeeItem);
            }
          }
          if ([...new Set(employeesInSalary1)].length === employeesArray.length) {
            setAllDone(true);
          }
        }
      }
    }
    setEmployeesInSalary([...new Set(employeesInSalary1)]);
    setFilteredEmployees(filteredEmployees1);
    setRandomTableKey(Math.random());
    setRandomKey(Math.random());
  }, [salaries, employees]);

  // prepare data for table --> row functions
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setResetFormFieldValues(true);
      if (selectedRows[0]._id === selectedEmployee) {
        setSelectedEmployeeIndicator(true);
      } else {
        setSelectedEmployeeIndicator(false);
      }
      setSelectedEmployee(selectedRows[0]._id);
      localStorage.removeItem('calendarData');
      setDisplay('block');
      setFileList([]);
      setSingleMonth(undefined);
      setCustomDay(false);
      setIsSelected(false);
      setShowCalendar(true);
    },
  };

  // prepare data for table
  useEffect(() => {
    let columnKeys = [];
    let newColumnKeys1 = [];
    let tableData = [];
    if (
      (filteredEmployees &&
        filteredEmployees.length > 0 &&
        (currentuser.data.role.includes('SuperAdmin') ||
          currentuser.data.role.includes('admin') ||
          currentuser.data.role.includes('user')) &&
        selectedCompany) ||
      (filteredEmployees &&
        filteredEmployees.length > 0 &&
        currentuser.data.role.includes('hrOfficer') &&
        selectedCompany) ||
      (filteredEmployees &&
        filteredEmployees.length > 0 &&
        !currentuser.data.role.includes('admin') &&
        !currentuser.data.role.includes('SuperAdmin'))
    ) {
      // updatedAtForShow variable does not exist on employee model but it's added here for display in table
      columnKeys = ['numRow', 'firstName', 'lastName', 'JMBG', 'recorded', 'endDate', 'updatedAtForShow'];
      let alternativeNames = [];
      for (const columnKey of columnKeys) {
        if (columnKey === 'numRow') {
          alternativeNames.push('Number');
        } else if (columnKey === 'firstName') {
          alternativeNames.push('Name');
        } else if (columnKey === 'lastName') {
          alternativeNames.push('Surname');
        } else if (columnKey === 'JMBG') {
          alternativeNames.push('Personal number');
        } else if (columnKey === 'recorded') {
          alternativeNames.push('Timesheet completion');
        } else if (columnKey === 'endDate') {
          alternativeNames.push('End Date');
        } else if (columnKey === 'updatedAtForShow') {
          alternativeNames.push('Updated on');
        }
      }
      let keyArray = columnKeys;
      for (let i = 0; i < columnKeys.length; i++) {
        newColumnKeys1.push({
          originalName: keyArray[i],
          alternativeName: alternativeNames[i],
        });
      }

      for (const employeeItem of filteredEmployees) {
        if (!selectedCompany) {
          setSelectedCompany(employeeItem.company._id);
        }
        employeeItem.numRow = filteredEmployees.indexOf(employeeItem) + 1;
        if (salaries?.data?.items?.length === 0) {
          employeeItem.submittedIndicator = 'NO';
          employeeItem.recorded = (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <div className='circleDiv'>
                <CloseOutlined
                  style={{ fontSize: '12px', color: '#343434' }}
                  onClick={() => {
                    const icons = document.getElementsByClassName('anticon');
                    for (var i = 0; i < icons.length; i++) {
                      var currentEl = icons[i];
                      currentEl.style.pointerEvents = 'none';
                    }
                    clickXSubmit(employeeItem._id, selectedYear, selectedMonth);
                  }}
                />
              </div>
            </div>
          );
        } else {
          for (const salary of salaries.data.items) {
            if (
              salary &&
              salary.employee &&
              salary.employee._id === employeeItem._id &&
              selectedMonth === salary.month &&
              salary.year === moment(selectedYear).format('YYYY')
            ) {
              let color = salary.submittedIndicator ? '#2db1ab' : '#c0c2c2';
              employeeItem.recorded = (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingLeft: '5px' }}>
                  <CheckCircleFilled style={{ fontSize: '20px', color: color }} />
                </div>
              );
              employeeItem.submittedIndicator = salary.submittedIndicator ? 'YES' : 'NO';
              break;
            } else {
              employeeItem.recorded = (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div className='circleDiv'>
                    <CloseOutlined
                      style={{ fontSize: '12px', color: '#343434' }}
                      onClick={() => {
                        const icons = document.getElementsByClassName('anticon');
                        for (var i = 0; i < icons.length; i++) {
                          var currentEl = icons[i];
                          currentEl.style.pointerEvents = 'none';
                        }
                        clickXSubmit(employeeItem._id, selectedYear, selectedMonth);
                      }}
                    />
                  </div>
                </div>
              );
            }
          }
        }
        const formattedDateUpdatedAt = formatDate(employeeItem.updatedAt);
        const formattedDateEndDate = employeeItem.endDate 
          ? formatEInvoiceDate(employeeItem.endDate)
          : 'unlimited';
        // setting this variable just for display in table
        employeeItem.updatedAtForShow = formattedDateUpdatedAt.toString();
        employeeItem.endDate = formattedDateEndDate.toString();
        tableData.push({ ...employeeItem });
      }
    }

    tableData.map((item) => {
      if (!item.submittedIndicator) {
        item.submittedIndicator = 'NO';
      }
    });

    setSalaryTableData({
      data: tableData,
      columnKeys: newColumnKeys1,
      rowSelection: rowSelection,
      tableKey: randomTableKey,
    });
  }, [employeesInSalary, filteredEmployees]);

  useEffect(() => {
    if (filteredEmployees) {
      setEmployeeReq(filteredEmployees.filter((item) => item._id === selectedEmployee));
    }
  }, [filteredEmployees, selectedEmployee]);

  // set parameter foundCompany, needed in salaryForm
  useEffect(() => {
    if (companies?.data && companies?.data?.items && companies?.data?.items[0]?._id) {
      setFoundCompany(true);
    }
  }, [companies]);

  // set isNew indicator for editing and submitting
  useEffect(() => {
    setIsNew(true);
    if (dataSalaries && selectedEmployee) {
      for (let item of dataSalaries) {
        if (item.employee?._id === selectedEmployee && item.month === selectedMonth && item.year == selectedYear) {
          setIsNew(false);
          break;
        }
      }
    }
  }, [dataSalaries, selectedEmployee, selectedMonth, selectedYear, calendarVisible, findedSalary]);

  useEffect(() => {
    if (selectedEmployee && salaries && dataTable && employeeReq) {
      setShowCalendar(true);
      setRandomKey(Math.random());
      setFileList([]);
    }
  }, [employeeReq]);

  useEffect(() => {
    if (salaryTableData && salaryTableData.data) {
      salaryTableData?.data.map((item) => {
        if (item.submittedIndicator === 'NO') {
          setAllDone(false);
        }
      });
    }
  }, [salaryTableData]);

  // set calendar off after salary submit
  useEffect(() => {
    if (submitted) {
      setShowCalendar(false);
    }
  }, [submitted]);

  useEffect(() => {
    setShowCalendar(true);
    setSubmitted(false);
  }, [selectedEmployee, selectedEmployeeIndicator]);

  // case when there is no chosen company and set it from user token content
  // if not admin or SuperAdmin, there is no need for fetching all companies
  // then it's working this way
  const handleGetLoggedUsersCompany = useCallback(async () => {
    const company = await Axios.get(`${SERVER_URL}/companies/${currentuser.data.company?._id}`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });
    setChosenCompany([company.data]);    
  }, [currentuser.data, currentuser.data.token])

  
  useEffect(() => {
    if (chosenCompany.length === 0 && currentuser.data.company) {
      handleGetLoggedUsersCompany();
    }
  }, [chosenCompany, currentuser, handleGetLoggedUsersCompany]);

  return (
    <div className='salary__wrapper'>
      <div>
        <>
          <div className='employ-company-section '>
            <SalaryFormCompanyPicker
              currentuser={currentuser}
              setShowCalendar={setShowCalendar}
              setSelectedEmployee={setSelectedEmployee}
              setDisplay={setDisplay}
              companyList={companyList}
              setSelectedCompany={setSelectedCompany}
              selectedCompany={selectedCompany}
              setUnsetEmployee={setUnsetEmployee}
            />
            <SalaryFormDatePicker
              setSelectedDate={setSelectedDate}
              setSelectedMonth={setSelectedMonth}
              windowWidth={windowWidth}
              years={years}
              selectedMonth={selectedMonth}
              selectedDate={selectedDate}
              setSelectedYear={setSelectedYear}
              selectedYear={selectedYear}
              setCalendarVisible={setCalendarVisible}
              setShowCalendar={setShowCalendar}
              setSelectedEmployee={setSelectedEmployee}
            />
            {chosenCompany && !!chosenCompany.length ? (
              <>
                <DocsSettings
                  chosenCompany={chosenCompany}
                  customDocsDrawer={customDocsDrawer}
                  setCustomDocsDrawer={setCustomDocsDrawer}
                />
                <CustomDocsDrawer
                  onClose={onClose}
                  customDocsDrawer={customDocsDrawer}
                  companyId={chosenCompany[0]?._id}
                  companyDocsSettings={chosenCompany[0]?.companyDocsSettings}
                  resetState={resetState}
                />
              </>
            ) : undefined}
          </div>
          <>
            <Table
              data={salaryTableData?.data}
              columnKeys={salaryTableData?.columnKeys}
              rowSelection={salaryTableData?.rowSelection}
              tableKey={randomTableKey}
            />
            {salaries && selectedYear && selectedMonth && selectedCompany && (
              <SalaryFormDownloadSection
                currentuser={currentuser}
                sendMail={sendMail}
                salaries={salaries}
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                selectedCompany={selectedCompany}
                selectedLang={selectedLang}
                setSelectedLang={setSelectedLang}
                allDone={allDone}
                foundCompany={foundCompany}
              />
            )}
            {selectedEmployee &&
            salaries &&
            showCalendar &&
            dataTable &&
            employeeReq &&
            employee &&
            !submitted &&
            employeeReq.length ? (
              <>
                <div ref={salaryFormFixRef}></div>
                <Divider type='horizontal' />
                <SalaryForm
                  salaryFormFixRef={salaryFormFixRef}
                  currentuser={currentuser}
                  key={randomKey}
                  salaries={dataSalaries}
                  onSubmit={onSubmit}
                  onDelete={deleteSalary}
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                  setSelectedMonth={setSelectedMonth}
                  display={display}
                  windowWidth={windowWidth}
                  setWindowWidth={setWindowWidth}
                  showCalendar={showCalendar}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  selectedEmployee={selectedEmployee}
                  employee={employee}
                  chosenCompany={chosenCompany}
                  findedSalary={findedSalary}
                  singleMonth={singleMonth}
                  setSingleMonth={setSingleMonth}
                  unsetEmployee={unsetEmployee}
                  employeesInSalary={employeesInSalary}
                  isNew={isNew}
                  setIsNew={setIsNew}
                  onXIcon={onXIcon}
                  setOnXIcon={setOnXIcon}
                  resetFormFieldValues={resetFormFieldValues}
                  fileList={fileList}
                  setFileList={setFileList}
                  customDay={customDay}
                  setCustomDay={setCustomDay}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  employeeFormData={employeeFormData}
                  setEmployeeFormData={setEmployeeFormData}
                  employeeReq={employeeReq}
                  updateDateEmployee={updateDateEmployee}
                  updateDateEmployeeSubmit={updateDateEmployeeSubmit}
                  handleFetchAndSetEmployee={handleFetchAndSetEmployee}
                  resetState={resetState}
                  setSubmitTimesheetDisabled={setSubmitTimesheetDisabled}
                  submitTimesheetDisabled={submitTimesheetDisabled}
                  setSubmitClicked={setSubmitClicked}
                  submitClicked={submitClicked}
                />
              </>
            ) : (
              <div />
            )}
            <Modal visible={isVisible} maskClosable={false} closable={false} footer={null}>
              <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
                <p>
                  Preparation of attendance sheet file and decisions. Sending of data on emails of responsible persons
                  is in progress.
                </p>
                <p>Please wait. This may take up to a minute depending on number of employees.</p>
                <p>
                  <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </p>
              </div>
            </Modal>
            <Modal visible={submitClicked} maskClosable={false} closable={false} footer={null}>
              <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
                <p>Please wait...</p>
                <p>
                  <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                </p>
              </div>
            </Modal>
          </>
        </>
      </div>
    </div>
  );
};

export default Salary;
