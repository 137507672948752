import React, { useEffect, useContext, useState } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { notification, Button, Modal, Spin } from 'antd';
import { UserContext } from '../../App';
import Table from '../../components/tables/InvoiceTable';
import { SERVER_URL } from '../../config';
import { formatEInvoiceDate } from '../../helpers/dates';
import { LoadingOutlined } from '@ant-design/icons';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'content',
  'orderId',
  'userEmail',
  'user',
  'products',
  'documentType',
  'type',
  'userCompany',
  'uploadedFiles',
  'customerId',
  'customerType',
  'vats',
  'comment',
  'subtotal',
  'paymentReference',
  'customerReference',
  'supplierReference',
  'openBalance',
  'total',
  'discounts',
  'totalVatExcl',
  'totalDiscounts',
  'interestArrears',
  'prepaidAmount',
  'poNumber',
  'customerNumber',
  'salesInvoiceId',
  'currency',
  'paymentMethod',
  'pdf',
  'supplierAddress',
  'supplierVat',
  'supplierCity',
  'supplierCountryCode',
  'supplierCompanyNumber',
  'supplierPostalCode',
  'customer',
  'createdAt',
  'updatedAt',
  'customerName',
  'customerPostalAddress',
  'customerVat',
  'customerCompanyNumber',
  'documentCurrency',
  'customerZipCode',
  'customerCountry',
  'supplierBankAccount',
  'customerCity',
  'paymentID',
  'paymentMethod',
  'vatSelected',
  'invoiceId',
  'customerCountry',
  'supplierMail',
  'supplierReference',
  'supplierStreetName',
  'totalDiscounts',
  'comment',
  'products',
  'supplyDate',
  'documentId',
  'creationDate',
  'sendingDate',
  'taxPointDate',
  'invoicePeriodStartDate',
  'invoicePeriodEndDate',
  'contractDocumentReference',
  'additionalDocumentReference',
  'attachment',
  'paymentMeansCode',
  'taxTotalAmount',
  'archived',
  'archivedDate',
  'recovered',
  'unifiedPdf',
  'dueDate',
  'documentDate'
];

const IncomingInvoices = () => {
  const currentuser = useContext(UserContext);
  const [purchases, setPurchases] = useState(undefined);
  const [purchasesFull, setPurchasesFull] = useState(undefined);
  const [isLoadingModalVisible, setIsLoadingModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [newColumnKeys, setNewColumnKeys] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataFullForSearch, setTableDataFullForSearch] = useState([]);
  const [customWidth, setCustomWidth] = useState(100);

  const history = useHistory();

  useEffect(() => {
    getPurchaseInvoices(pagination);
    getPurchaseInvoicesFullForSearch();
  }, []);

  const getPurchaseInvoices = async (paginationObject) => {
    const { current, pageSize } = paginationObject;

    const filters = {
      companyId: currentuser.data.company._id,
      page: current,
      pageSize,
    };

    try {
      const response = await Axios.get(`${SERVER_URL}/get-purchase`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      const { total } = response.data;

      setPurchases(response.data);
      setPagination({ ...paginationObject, total });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getPurchaseInvoicesFullForSearch = async () => {
    const filters = {
      companyId: currentuser.data.company._id,
    };

    try {
      const response = await Axios.get(`${SERVER_URL}/get-purchase`, {
        params: filters,
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setPurchasesFull(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const deleteInvoice = async (id, status) => {
    try {
      if (status === 'Draft') {
        let companyId = currentuser.data.company._id;
        await Axios.delete(`${SERVER_URL}/delete-draft/${id}?filter=${JSON.stringify(companyId)}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
      }
      notification.success({
        message: 'The invoice has been deleted.',
        placement: 'bottomRight',
      });
      history.push('/admin/accounts-payable');
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const purchaseScript = async () => {
    try {
      setIsLoadingModalVisible(true);
      const company = currentuser?.data.company;
      const response = await Axios.post(`${SERVER_URL}/purchase-script`, {
        company,
      });
      notification.success({
        message: 'Successfully sync. with SEF',
        placement: 'bottomRight',
      });
      return response.data;
    } catch (error) {
      console.log('error in sync with SEF', error);
      notification.error({
        message: 'You are unauthorized according to SEF! Please, double check your API key.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        setIsLoadingModalVisible(false)
        getPurchaseInvoices(pagination);
      }, 1200);
    }
  };

  const archiveInvoice = async (id) => {
    try {
      let companyId = currentuser.data.company._id;
      await Axios.post(
        `${SERVER_URL}/archive-purchase/${id}?filter=${JSON.stringify(companyId)}`,
        {},
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );
      notification.success({
        message: 'The invoice has been archived.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with archiving. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        getPurchaseInvoices(pagination);
        getPurchaseInvoicesFullForSearch();
      }, 700);
    }
  };

  const acceptInvoice = async (id, comment) => {
    try {
      let companyId = currentuser.data.company._id;
      await Axios.post(`${SERVER_URL}/accept-purchase/${id}?filter=${JSON.stringify(companyId)}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      notification.success({
        message: 'The invoice has been accepted.',
        placement: 'bottomRight',
      });
    } catch (err) {
      notification.error({
        message: 'Problem with accepting. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      setTimeout(() => {
        getPurchaseInvoices(pagination);
      }, 700);
    }
  };

  const rejectInvoice = async (id, comment) => {
    try {
      let companyId = currentuser.data.company._id;
      await Axios.post(`${SERVER_URL}/reject-purchase/${id}?filter=${JSON.stringify(companyId)}`, comment, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      }).then((res) => {
        if (res.status === 200) {
          notification.success({
            message: 'The invoice has been rejected.',
            placement: 'bottomRight',
          });
          setTimeout(() => {
            getPurchaseInvoices(pagination);
          }, 1000);
        }
      });
    } catch (err) {
      notification.error({
        message: 'Problem with rejecting. Please try later.',
        placement: 'bottomRight',
      });
    }
  };
  const checkStatusPurchase = async (id, paginationObject) => {
    try {
      let companyId = currentuser.data.company._id;
      const statusData = await Axios.get(`${SERVER_URL}/purchase-status/${id}?filter=${JSON.stringify(companyId)}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      return statusData.data.status;
    } catch (error) {
      notification.error({
        message: 'Problem with checking invoice status. Please try later.',
        placement: 'bottomRight',
      });
    } finally {
      // Update the pagination to retain the current page position
      setPagination(paginationObject);
    }
  };

  useEffect(() => {
    let columnKeys;
    let newColumnKeysForState;
    if (purchases?.data && purchases?.data?.length > 0) {
      const keys = Object.keys(purchases?.data[0]);
      columnKeys = keys?.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
      let alternativeNames = [];
      for (const columnKey of columnKeys) {
      if (columnKey === 'documentNumber') {
          alternativeNames.push('Invoice No');
        } else if (columnKey === 'purchaseInvoiceId') {
          alternativeNames.push('SEF No');
        } else if (columnKey === 'deliveryDate') {
        alternativeNames.push('Delivery Date');
        } else if (columnKey === 'paymentDate') {
          alternativeNames.push('Due Date');
        } else if (columnKey === 'supplierName') {
          alternativeNames.push('Supplier Name');
        } else if (columnKey === 'status') {
          alternativeNames.push('Status');
        }
      }
  
      let keyArray = columnKeys;
      newColumnKeysForState = columnKeys?.map((item, index) => ({
        originalName: keyArray[index],
        alternativeName: alternativeNames[index],
      }));

      setNewColumnKeys(newColumnKeysForState);
    }
  
    let tableDataForState = [];
    if (purchases?.data && purchases?.data?.length > 0) {
      tableDataForState = purchases?.data?.map((item) => {
        const formattedDate = formatEInvoiceDate(item.deliveryDate);
        const formattedDate2 = item.dueDate !== null ? formatEInvoiceDate(item.dueDate) : 'N/A';
        const formattedDate3 = item.paymentDate !== null ? formatEInvoiceDate(item.paymentDate) : 'N/A';
        if (item.status === '') item.status = ' ';
        return { ...item, deliveryDate: formattedDate, dueDate: formattedDate2, paymentDate: formattedDate3 };
      });
      setTableData(tableDataForState)
    }
  
    let tableDataFullForSearchForState = [];
    if (purchasesFull?.data && purchasesFull?.data?.length > 0) {
      tableDataFullForSearchForState = purchasesFull?.data?.map((item) => {
        const formattedDate = formatEInvoiceDate(item.deliveryDate);
        const formattedDate2 = item.dueDate !== null ? formatEInvoiceDate(item.dueDate) : 'N/A';
        if (item.status === '') item.status = ' ';
        return { ...item, deliveryDate: formattedDate, dueDate: formattedDate2 };
      });
      setTableDataFullForSearch(tableDataFullForSearchForState)
    }
  
    // Define the allowed status values
    const allowedStatusValues = ['Accepted', 'Rejected', 'Approved', 'Storno'];
  
    // Check if all objects have a valid status and set custom width of the Options column in table
    const allObjectsHaveValidStatus = tableData.every((item) => {
      return item.status && allowedStatusValues.includes(item.status);
  
    });
  
    setCustomWidth(allObjectsHaveValidStatus ? 100 : 240);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchases?.data, purchasesFull?.data]);


  return (
    <>
      <div style={{ padding: '8px' }}>
        <div
          style={{
            padding: '8px',
            textAlign: 'right',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '10px',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingRight: '30px',
              fontSize: '0.9rem',
            }}
          >
            <div>*** after 3 months, incoming E-Invoices are automatically transferred to the Archive section</div>
          </div>
          <Button onClick={purchaseScript} type='primary' style={{ marginLeft: '10px' }}>
            Sync with SEF
          </Button>
        </div>
        {tableData.length && tableDataFullForSearch.length ? (
          <Table
            columnKeys={newColumnKeys}
            dataSource={tableData}
            dataForSearch={tableDataFullForSearch}
            deleteHandler={deleteInvoice}
            archiveHandler={archiveInvoice}
            acceptHandler={acceptInvoice}
            rejectHandler={rejectInvoice}
            purchaseHandler={checkStatusPurchase}
            customWidth={customWidth}
            handlePaginationChange={getPurchaseInvoices}
            pagination={pagination}
            setPagination={setPagination}
          />
        ) : (
          <div style={{display: 'flex', height: '60vh', justifyContent: 'center', alignItems: 'center'}}>
            <Spin 
              style={{ color: '#2db1ab' }} 
              indicator={
                <LoadingOutlined 
                  style={{ fontSize: 44 }} 
                  spin
                />
              } 
            />
          </div>
        )}
       
        <Modal 
          visible={isLoadingModalVisible} 
          maskClosable={false} 
          closable={false} 
          footer={null}
        >
          <div style={{ textAlign: 'center', fontSize: '1.05rem' }}>
            <p>Syncing Invoices with SEF.</p>
            <p>Please wait. This may take up to a minute depending on number of invoices.</p>
            <p>
              <Spin style={{ color: '#2db1ab' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            </p>
          </div>
        </Modal>
      </div>

      {purchases?.data && purchases?.data?.length === 0 && <h2 style={{ textAlign: 'center' }}>NO DATA</h2>}
    </>
  );
};

export default IncomingInvoices;
