import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu, notification } from 'antd';
import { QuestionOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import HrLogoSeparator from '../custom/HrLogoSeparator';
import {
  ArchiveIcon,
  BookDarkIcon,
  CalculatorSubIcon,
  CustomersIcon,
  HRIcon,
  HrRequestIcon,
  InvoiceIcon,
  InvoiceOutgoingIcon,
  InvoicesIncomingIcon,
  RulesIcon,
  SubInvoiceIcon,
  ViewAllRequestsIcon,
  ViewRequestIcon,
} from '../icons';
import CalculatorIcon from '../icons/CalculatorIcon';
import { useThemeContext, useThemeContextDispatch } from '../../context/ThemeContext';
import ThemeSwitch from '../custom/button/theme-switch/ThemeSwitch';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { collapsedNav } = props;
  const [canAccessEInvoices, setCanAccessEInvoices] = useState(false);
  const [canSeeEInvoicesMenu, setCanSeeEInvoicesMenu] = useState(true);
  const user = useContext(UserContext);

  const { theme } = useThemeContext();
  const { toggleTheme } = useThemeContextDispatch();

  const isCollapsed = true;

  useEffect(() => {
    if (user?.data?.company?._id) {
      setCanAccessEInvoices(true);
    }
  }, [user]);

  // checking if company has EInvoices Officer in which case 'user' role can't see e-invoices menu
  // REVISION March 2024 --> this is set again to be like opposite to above
  useEffect(() => {
    if (
      (user?.data?.role?.includes('user') && !user?.data?.company?.eInvoiceOfficer) ||
      (!user?.data?.role?.includes('user') &&
        user?.data?.company?.eInvoiceOfficer &&
        user?.data?.id === user?.data?.company?.eInvoiceOfficer) ||
      //** this allows User to have access at the same time as eInvoice Officer
      (user?.data?.role?.includes('user') &&
        user?.data?.company?.eInvoiceOfficer &&
        user?.data?.id !== user?.data?.company?.eInvoiceOfficer) ||
      //**
      (user?.data?.role?.includes('user') && user?.data?.role?.includes('eInvoiceOfficer'))
    ) {
      setCanSeeEInvoicesMenu(true);
    }
    if (!user?.data?.role?.includes('user') && !user?.data?.role?.includes('eInvoiceOfficer')) {
      setCanSeeEInvoicesMenu(false);
    }
  }, [user]);

  const checkAccessToEInvoices = () => {
    if (!canAccessEInvoices && !user?.data?.company?.apiKey) {
      notification.error({
        message: 'Please set all required data before use of E-Invoices platform',
        placement: 'bottomRight',
      });
    }
  };

  const filteredRoutes = ROUTES.filter(
    (route) => user.data && user.data.role && route.allowed.some((role) => user.data.role.includes(role)),
  );

  // this filters out Create request route from side menu for hrOfficer which is not employee, he could not create request for himself
  let filteredRoutes2 = filteredRoutes
    .map((route) => {
      const hasHrOfficer = user?.data?.role?.includes('hrOfficer');
      const hasEmployee = user?.data?.role?.includes('employee');
      const companyHasSectors = user?.data?.company?.isSector;
      const isUserAndUserOfficerRole = user?.data?.role?.includes('user') || user?.data?.role?.includes('userOfficer');

      if (route.label === 'Create request') {
        if (hasHrOfficer && hasEmployee) {
          return route; // Allow the route for users with both roles
        } else {
          return null; // Exclude the route for users with only 'hrOfficer' role
        }
      }

      // this excludes menu children related to Sectors for user/userOfficer if company has no sectors
      // also change the label for Company
      const sectorLabels = ['All sectors', 'New sector', 'Edit sector', 'View sector'];
      if (route.children && isUserAndUserOfficerRole) {
        route.children = route.children
          .map((child) => {
            if (child.label === 'All companies') {
              child.label = 'Company data';
            }
            return child;
          })
          .filter((child) => {
            if (sectorLabels.includes(child.label)) {
              return companyHasSectors;
            }
            return true;
          });
      }

      return route;
    })
    .filter((route) => route !== null);

  return (
    <Sider
      breakpoint='lg'
      className={`${collapsedNav ? 'nav-menu-collapsed' : 'nav-display'} nav-sider__wrapper`}
      trigger={null}
      collapsible
      collapsed={true}
    >
      <Link to='/admin' className='logo-desktop logo-outer__wrapper'>
        <div className={`logo logo-inner__wrapper ${theme === 'dark' ? '' : 'dark-theme'}`}>
          <img
            src={'/logoHLB.png'}
            className='logo-image__element'
            alt='HLB api'
          />
        </div>
        <div className='yellow-horizontal-header-line__element'></div>
      </Link>
      <div className={theme === 'dark' ? 'green-vertical-line__element' : 'black-vertical-line__element'}></div>
      <Menu
        theme={theme}
        className='sidebar-items__wrapper'
        mode='inline'
        defaultSelectedKeys={[window.location.pathname]}
      >
        {filteredRoutes2.map((route) => {
          if (
            (user?.data?.role?.includes('SuperAdmin') ||
              user.data.role.includes('admin') ||
              user?.data?.role?.includes('eInvoiceOfficer') ||
              user?.data?.role?.includes('hrOfficer')) &&
            route?.children[0]?.showInMenu
          ) {
            return (
              <SubMenu
                key={route.label.toLowerCase()}
                title={
                  isCollapsed ? (
                    <>{route.icon}</>
                  ) : (
                    <span>
                      {route.icon}
                      {route.label}
                    </span>
                  )
                }
                className='menu-item__wrapper'
              >
                {route &&
                  route.children &&
                  route.children
                    .filter((item) => item.showInMenu)
                    .map((item) => (
                      <Menu.Item key={item.path}>
                        <Link to={item.path}>
                          {item.icon ? item.icon : ''} <span className='sub-menu-item__text'>{item.label}</span>
                        </Link>
                      </Menu.Item>
                    ))}
              </SubMenu>
            );
          } else {
            if (route.label === 'E-invoices') {
              return '';
            } else {
              return (
                <SubMenu
                  key={route?.label?.toLowerCase()}
                  className='menu-item__wrapper'
                  title={
                    isCollapsed ? (
                      <>{route.icon}</>
                    ) : (
                      <span>
                        {route.icon}
                        {route.label}
                      </span>
                    )
                  }
                >
                  {route &&
                    route.children &&
                    route.children
                      .filter((item) => item.showInMenu)
                      .map((item) => (
                        <Menu.Item key={item.path}>
                          <Link
                            style={{ textDecoration: 'none' }}
                            onClick={() => {
                              if (window.location.pathname.includes('salary')) {
                                document.body.scrollTop = 0;
                              }
                            }}
                            to={item.path}
                          >
                            {item.icon ? item.icon : ''} <span className='sub-menu-item__text'>{item.label}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                </SubMenu>
              );
            }
          }
        })}
        {user?.data?.role?.includes('employee') && !user?.data?.role?.includes('hrOfficer') && (
          <SubMenu className='menu-item__wrapper' title={<EyeOutlined />} key='View my requests'>
            <Menu.Item key='2'>
              <Link to='/admin/hr-platform-requests'>View my requests</Link>
            </Menu.Item>
          </SubMenu>
        )}

        {user?.data?.role?.includes('employee') && !user?.data?.role?.includes('hrOfficer') && (
          <SubMenu className='menu-item__wrapper' title={<PlusCircleOutlined />} key='Create request'>
            <Menu.Item key='3' style={{ marginTop: 0 }}>
              <Link to='/admin/hr-platform-employee'>Create request</Link>
            </Menu.Item>
          </SubMenu>
        )}
        {!(user?.data?.role?.includes('employee') && !user?.data?.role?.includes('hrOfficer')) && (
          <>
            {(user?.data?.company?.eInvoice || user?.data?.role?.includes('SuperAdmin')) && canSeeEInvoicesMenu && (
              <SubMenu
                className='menu-item__wrapper'
                title={'Invoices'}
                key='1'
                icon={<InvoiceIcon />}
                onClick={checkAccessToEInvoices}
              >
                <Menu.Item key='11'>
                  <Link to={canAccessEInvoices ? '/admin/create-new-invoice' : ''}>
                    <SubInvoiceIcon className='sub-menu__icon' />
                    <span className='sub-menu-item__text'>New invoice</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key='7'>
                  <Link to={canAccessEInvoices ? '/admin/accounts-receivable' : ''}>
                    <InvoiceOutgoingIcon className='sub-menu__icon' />
                    <span className='sub-menu-item__text'>Outgoing invoices</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key='8'>
                  <Link to={canAccessEInvoices ? '/admin/accounts-payable' : ''}>
                    <InvoicesIncomingIcon className='sub-menu__icon' />
                    <span className='sub-menu-item__text'>Incoming invoices</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key='9'>
                  <Link to={canAccessEInvoices ? '/admin/customers' : ''}>
                    <CustomersIcon className='sub-menu__icon' />
                    <span className='sub-menu-item__text'>Customers</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key='10'>
                  <Link to={canAccessEInvoices ? '/admin/archive' : ''}>
                    <ArchiveIcon className='sub-menu__icon' />
                    <span className='sub-menu-item__text'>Archive</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            )}
            <HrLogoSeparator theme={theme}/>
            {user?.data?.role?.includes('SuperAdmin') ||
            user?.data?.role?.includes('admin') ||
            user?.data?.role?.includes('userOfficer') ||
            user?.data?.role?.includes('hrOfficer') ||
            user?.data?.role?.includes('user') ? (
              <SubMenu className='menu-item__wrapper' key={'HR REQ'} title={<HRIcon />}>
                <Menu.Item key='2'>
                  <Link to='/admin/hr-platform-requests'>
                    <HrRequestIcon className='sub-menu__icon' />
                    <span className='sub-menu-item__text'>HR - View all requests</span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              ''
            )}
            {user?.data?.role?.includes('SuperAdmin') ||
              (user?.data?.role?.includes('admin') && (
                <SubMenu className='menu-item__wrapper' key={'Questionnaire'} title={<QuestionOutlined />}>
                  <Menu.Item key='4'>
                    <Link to='/admin/questionnaire'>Questionnaire</Link>
                  </Menu.Item>
                </SubMenu>
              ))}
            {(user?.data?.role?.includes('SuperAdmin') ||
              user?.data?.role?.includes('admin') ||
              user?.data?.role?.includes('user') ||
              user?.data?.role?.includes('userOfficer') ||
              user?.data?.role?.includes('eInvoiceOfficer')) && (
              <>
                <SubMenu className='menu-item__wrapper' key={'Calculator'} title={<CalculatorIcon />}>
                  <Menu.Item key='5'>
                    <Link to='/admin/salary-calculator'>
                      <CalculatorSubIcon className='sub-menu__icon' />
                      <span className='sub-menu-item__text'>Calculator</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  className='menu-item__wrapper'
                  key={'Guidelines'}
                  title={theme === 'dark' ? <BookDarkIcon /> : <BookDarkIcon />}
                >
                  <Menu.Item key='6'>
                    <Link to='/admin/guidelines'>
                      <RulesIcon className='sub-menu__icon' />
                      <span className='sub-menu-item__text'>Guidelines</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </>
            )}
          </>
        )}
        {user?.data?.role?.includes('employee') && user?.data?.role?.includes('hrOfficer') && (
          <SubMenu className='menu-item__wrapper' key={'My requests'} title={<ViewRequestIcon />}>
            <Menu.Item key='2'>
              <Link to='/admin/hr-officer-requests'>
                <ViewAllRequestsIcon className='sub-menu__icon' />
                <span className='sub-menu-item__text'>View my requests</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        )}
      </Menu>

      <ThemeSwitch isChecked={theme === 'dark'} handleChange={() => toggleTheme()} />
    </Sider>
  );
};
export default NavMenu;
